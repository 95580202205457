<template>
  <v-badge
  color="red"
  :content="value > 9 ? '9+' : value"
  inline
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    }
  }
}
</script>

<style>

</style>