<template>
  <v-container fluid>
    <client-action-dialog v-if="clientActionDialogModel" v-model="clientActionDialogModel"/>
    <v-row dense no-gutters>
      <v-col>
        <main-data-table
        :headers="headers"
        :items="items"
        number-pages
        :page.sync="page"
        :size.sync="size"
        :onUpdateSize="tryToFetchRepairs"
        :onPerviousBtnClick="tryToFetchRepairs"
        :onNextBtnClick="tryToFetchRepairs"
        :total-elements="totalElements"
        :loading="isLoading"
        class="pa-4">
          <template v-slot:[`header.id`]="{ header }">
            {{$t(header.text)}}
          </template>
          <template v-slot:[`header.status`]="{ header }">
            {{$t(header.text)}}
          </template>
          <template v-slot:[`header.actionRequired`]="{ header }">
            {{$t(header.text)}}
          </template>
          <template v-slot:[`header.createdAt`]="{ header }">
            {{$t(header.text, {'0': getTimeZoneInNumber(new Date())})}}
          </template>
          <template v-slot:[`header.updatedAt`]="{ header }">
            {{$t(header.text, {'0': getTimeZoneInNumber(new Date())})}}
          </template>
          <template v-slot:[`header.details`]="{ header }">
            {{$t(header.text)}}
          </template>
          
          <template v-slot:[`item.id`]="{ item }">
            <span>
              {{item.number}}
            </span> 
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span>
              {{$t(getStatus(item.status))}}
            </span>
          </template>
          <template v-slot:[`item.actionRequired`]="{ item }">
            <span>
              {{$t('other.from')}} {{item.actionRequired.toLowerCase()}}
            </span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <span>
              {{getDateNewYorkFormat(item.createdAt)}}
            </span>
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            <span>
              {{getDateNewYorkFormat(item.updatedAt)}}
            </span>
          </template>
          <template v-slot:[`item.details`]="{ item }">
            <route-btn
            :href="`process-repair/${item.id}`"
            label="btns.viewDetails"
            :badge="item.actionRequiredFromCurrentUser ? 1 : null"
            :width="120"
            :height="30"/>
          </template>
        </main-data-table>
      </v-col>
    </v-row>

    <export-xlsx-row
    :width="150"
    :height="50"
    :disabled="items.length === 0"
    :loading.sync="isLoading"
    class="px-4"
    name="export.title.activeRepairs"
    :exporter="getExporter"/>
  </v-container>
</template>
<script>
import { fetchActiveRepairs } from '@/repositories/repairRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { getRepairStatusByKey } from '@/utils/statusesUtil'
import DateUtil from '@/utils/DateUtil'
import ExportXlsxRow from '@/components/common/ExportXlsxRow'
import { routeFromRoot } from '@/services/routeService'
import { isLanguageEn, getUserRole } from '@/services/authService'
import { exportActiveRepairsXlsx } from '@/repositories/exportRepo'
import MainDataTable from '@/components/common/MainDataTable'
import ClientActionDialog from '@/components/dialogs/ClientActionDialog'
import RouteBtn from '@/components/btns/RouteBtn'

export default {
  components: {
    MainDataTable,
    ClientActionDialog,
    ExportXlsxRow,
    RouteBtn
  },
  data() {
    return {
      headers: [
        {
          text: 'headers.requestId',
          align: 'start',
          sortable: true,
          value: 'id'
        },{
          text: 'headers.status',
          align: 'start',
          sortable: true,
          value: 'status'
        },{
          text: 'headers.actionRequired',
          align: 'start',
          sortable: true,
          value: 'actionRequired'
        },{
          text: 'headers.creationDateTime',
          align: 'start',
          sortable: true,
          value: 'createdAt' //(yyyy-MM-dd HH:mm)
        }, {
          text: 'headers.lastChangeDateTime',
          align: 'start',
          sortable: true,
          value: 'updatedAt' //(yyyy-MM-dd HH:mm)
        }, {
          text: 'headers.details',
          align: 'start',
          sortable: true,
          value: 'details'
        }
      ],
      items: [],
      totalElements: 0,
      size: 10,
      page: 1,
      isLoading: false,
      clientActionDialogModel: false
    }
  },
  computed: {
    isEN() {
      return isLanguageEn()
    },
    getRole() {
      return getUserRole()
    },
    getExporter() {
      return exportActiveRepairsXlsx
    }
  },
  methods: {
    async tryToFetchRepairs() {
      this.items = []
      const r = await execHttpRequestThrowable(async () => await fetchActiveRepairs(this.size, this.page - 1, "createdAt,DESC"),
      {},
      v => this.isLoading = v)
      this.pageable = r.response.data
      this.totalElements = this.pageable.totalElements
      this.items = this.pageable.content
    },
    getDateNewYorkFormat(d) {
      return DateUtil.getDateNewYorkFormat(d)
    },
    getStatus(k) {
      return getRepairStatusByKey(k)
    },
    routeTo(link) {
      routeFromRoot(link)
    },
    getTimeZoneInNumber(d) {
      return DateUtil.getTimeZoneInNumber(d)
    }
  },
  async mounted() {
    await this.tryToFetchRepairs()
  },
  created() {
    if (this.$route.hash === '#suggest-options' && this.getRole === 'CLIENT') {
      this.clientActionDialogModel = true
    }

    this.$feedback.fill("Active Repairs Page", this.$route.path)
  }
}
</script>
<style>
.main-data-table.theme--light.v-data-table {
  background: #ffffff;
}
.main-data-table.v-data-table {
  background: transparent !important;
}
/*
Rows per page color!
.main-data-table .v-select__selection.v-select__selection--comma {
  color: #DF9811;
}
*/
.main-data-table.v-icon.notranslate .mdi.mdi-menu-down.theme--light.primary--text {
  color: #DF9811;
}
/* 
Rows per page underline color!
.main-data-table .v-select > .v-input__control > .v-input__slot{
  color: #DF9811;
}
*/
/*
Right arrow color! 
.main-data-table .v-btn--icon.v-size--default .v-icon {
  color: #DF9811;
}
*/
.main-data-table.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row) {
  border-color: transparent;
}

.main-data-table .v-data-table-header {
  background-color: #cfd8dc!important;
}
thead.v-data-table-header > tr > th.text-start {
  border-left: white 1px solid;
  border-right: white 1px solid;
}

.main-data-table-header {
  color: #000000;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
  letter-spacing: 1px;
}

.detail-btn.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light {
  color: #fff;
  background-color: #4285f4!important;
  border-color: #75a4f0;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  font-size: 0.65rem;
  letter-spacing: 1px;
}
</style>