import { get, put, post } from '@/services/http'
import { getUserToken } from '@/services/authService'

export function createRemoteSupport(data) {
  return put(`/api/v1/support/save`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}

export function findRemoteSupportById(id) {
  return get(`/api/v1/support/fetch/by/id?id=${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function updateInitialApproval(id, status) {
  return post(`/api/v1/support/update/initial-approval?remoteSupportId=${id}&status=${status}`, {}, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}

export function updateRemoteSupportStatus(id, s) {
  return put(`/api/v1/support/update-status?remoteSupportId=${id}${s ? `&status=${s}` : ''}`, {}, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}

export function fetchRemoteSupportByCriteria(size, page, sort) {
  return get(`/api/v1/support/fetch-remote-support-by-criteria?size=${size}&page=${page}&sort=${sort}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}

export function fetchRemoteSupportHistoryStatusByCriteria(id, size, page, sort) {
  return get(`/api/v1/support/fetch/remote-support/history?id=${id}&size=${size}&page=${page}&sort=${sort}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}

export function tryToRequestUsernameByEmail(e) {
  return put(`/api/v1/support/request-username-by-email?email=${e}`, {}, {
    headers: {
    }
  })
}

export function requestFeedback(d) {
  return put(`/api/v1/support/request/feedback`, d, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}
export function updateServiceReviewData(id, d) {
  return post(`/api/v1/support/update/service-review?id=${id}`, d, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
export function updateServiceReviewSummary(id, summary) {
  return put(`/api/v1/support/update/service-review/summary?id=${id}`, summary, {
    headers: {
      'Content-Type': 'text/plain',
      'Authorization': getUserToken()
    }
  })
}
export function changeRequestService(requestId, serviceId) {
  return put(`/api/v1/support/change/service?requestId=${requestId}&serviceId=${serviceId}`, {empty: true}, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}