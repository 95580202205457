<template>
  <v-btn 
  :to="hrefLocal"
  class="detail-btn rounded-0"
  :target="getTarget"
  :width="width" 
  :height="height">
    {{$t(label)}}
    <v-badge
    v-if="badge"
      color="red"
      :content="badge"
      inline
    />
  </v-btn>
</template>
<script>
import { getSelectedLanguage } from '@/services/authService'
export default {
  props: {
    //Required
    label: {
      type: String,
      required: true
    },
    href: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: 'self'
    },
    //Non-required
    width: {
      type: Number,
      default: () => {
        return 120
      }
    },
    height: {
      type: Number,
      default: () => {
        return 30
      }
    },
    badge: {
      type: [String, Number],
      default: () => {
        return null
      }
    }
  },
  data: () => {
    return {
      hrefLocal: null
    }
  },
  computed: {
    getTarget() {
      return `_${this.target.replaceAll(/^_/g, '')}`
    }
  },
  created() {
    let h = this.href
    if (h.charAt(0) === '/') h = h.substring(1)

    this.hrefLocal = `/${getSelectedLanguage()}/${h}`
  }
}
</script>
<style>

</style>