<template>
  <v-container fluid>
    <v-row dense no-gutters>
      <v-col>
        <main-data-table
        number-pages
        :headers="getHeaders()"
        :items="items"
        :page.sync="page"
        :size.sync="size"
        :loading="isLoading"
        :onUpdateSize="tryToFetchRemoteSupport"
        :onPerviousBtnClick="tryToFetchRemoteSupport"
        :onNextBtnClick="tryToFetchRemoteSupport"
        :total-elements="totalElements">
          <template v-slot:[`header.preferredDate`]="{ header }">
            {{$t(header.text, {'0': getTimeZoneInNumber(new Date())})}}
          </template>
          <template v-slot:[`header.preferredTime`]="{ header }">
            {{$t(header.text, {'0': getTimeZoneInNumber(new Date())})}}
          </template>
          <template v-slot:[`header.createdAt`]="{ header }">
            <span v-html="$t(header.text, {'0': getTimeZoneInNumber(new Date())})"></span>
          </template>
          <template v-slot:[`header.updatedAt`]="{ header }">
            <span v-html="$t(header.text, {'0': getTimeZoneInNumber(new Date())})"></span>
          </template>
          <template v-slot:[`header.status`]="{ header }">
            {{$t(header.text)}}
          </template>


          <!-- <template 
          v-slot:[`item.phone`]="{ item }">
              <span>
                {{item.contact.phone}}
              </span>
          </template> -->
          <template 
          v-if="getRole !== 'CLIENT'"
          v-slot:[`item.clientName`]="{ item }">
              <span>
                {{item.clientName}}
              </span>
          </template>
          <template 
          v-if="getRole === 'ADMIN'"
          v-slot:[`item.service`]="{ item }">
              <div
              class="my-2">
                <text-field-autocomplete 
                v-model="item.service"
                :label="$t('roles.service')"
                item-text="name"
                item-value="id"
                :items="services"
                required
                :clearable="false"
                :disabled="isRequestDone(item.status)"
                @input="openConfirmDialog(item)"/>
              </div>
          </template>
          <template v-slot:[`item.fullName`]="{ item }">
              <span>
                {{item.contact.firstName.concat(' ').concat(item.contact.lastName)}}
              </span>  
          </template>
          <!-- <template v-slot:[`item.email`]="{ index, item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <td 
                  v-bind="attrs"
                  v-on="on"
                  @click="item.isEmailExpanded = !item.isEmailExpanded"
                  :class="`main-data-table-row ${(index % 2) === 0 ? 'main-data-table-row-color-fill' : ''}`"
                  style="cursor: pointer;">
                    <span>
                      {{item.contact.email}}
                    </span>  
                    <div v-if="item.isEmailExpanded">
                      <v-row
                      dense
                      no-gutters
                      v-for="(e, i) in item.contact.backupEmails" :key="i">
                        <v-col>
                          <span>{{e.value}}</span>
                        </v-col>
                      </v-row>
                    </div>
                  </td>
                </template>
                <span>{{$t(item.isEmailExpanded ? 'pages.remoteSupport.toolTipClickToCollapse' : 'pages.remoteSupport.toolTipClickToExpand')}}</span>
              </v-tooltip>
          </template> -->
          <!-- <template v-slot:[`item.description`]="{ index, item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <td 
                  v-bind="attrs"
                  v-on="on"
                  @click="item.isDescriptionExpanded = !item.isDescriptionExpanded"
                  :class="`main-data-table-row ${(index % 2) === 0 ? 'main-data-table-row-color-fill' : ''}`"
                  style="cursor: pointer;">
                    <span>
                      {{item.isDescriptionExpanded ? item.description : item.shortDescription}}
                    </span>
                  </td>
                </template>
                <span>{{$t(item.isDescriptionExpanded ? 'pages.remoteSupport.toolTipClickToCollapse' : 'pages.remoteSupport.toolTipClickToExpand')}}</span>
              </v-tooltip>
          </template> -->
          <template v-slot:[`item.preferredDate`]="{ item }">
              <span>
                {{extractDate(item.preferredDate)}}
              </span>
          </template>
          <template v-slot:[`item.preferredTime`]="{ item }">
              <span>
                {{extractTime(item.preferredDate)}}
              </span>
          </template>
           <template v-slot:[`item.preferredContactMethods`]="{ item }">
              <span>
                {{makePreferredContactMethodsPreviewable(item.preferredContactMethods)}}
              </span>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
              <span v-html="breakDateAndTimeLabels(getDateNewYorkFormat(item.createdAt))">
              </span>
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
              <span v-html="breakDateAndTimeLabels(getDateNewYorkFormat(item.updatedAt))">
              </span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
              {{$t(item.statusObj.value)}}
          </template>
          <template v-slot:[`item.details`]="{ item }">
              <route-btn
              label="btns.viewDetails"
              :href="`/remote-support/process/${item.id}`"
              :badge="item.actionRequiredFromCurrentUser ? 1 : null"/>
          </template>
        </main-data-table>
      </v-col>
    </v-row>

    <export-xlsx-row
    :width="150"
    :height="50"
    :disabled="items.length === 0"
    :loading.sync="isLoading"
    class="px-4 mt-4"
    name="export.title.remoteSupport"
    :exporter="getExporter"/>
  </v-container>
</template>
<script>
import { changeRequestService, fetchRemoteSupportByCriteria } from '@/repositories/supportRepo'
import { execHttpRequestThrowable } from '@/services/http'
import MainDataTable from '@/components/common/MainDataTable'
import ExportXlsxRow from '@/components/common/ExportXlsxRow'
import DateUtil from '@/utils/DateUtil'
import RouteBtn from '@/components/btns/RouteBtn'
import TextFieldAutocomplete from '@/components/text-fields/TextFieldAutocomplete'
import { isLanguageEn, getUserRole } from '@/services/authService'
import { REMOTE_SUPPORT_STATUS, getObjectRemoteSupportStatusByKey } from '@/utils/statusesUtil'
import { exportRemoteSupportXlsx } from '@/repositories/exportRepo'
import { fetchAllServices } from '@/repositories/serviceRepo'
import { copyObj } from '@/utils/StringUtil'

export default {
  components: {
    MainDataTable,
    ExportXlsxRow,
    RouteBtn,
    TextFieldAutocomplete
  },
  data() {
    return {
      headers: [
        {
          text: 'headers.requestId',
          align: 'start',
          sortable: true,
          value: 'requestId'
        }, {
          text: 'headers.clientName',
          align: 'start',
          sortable: true,
          value: 'clientName'
        }, {
          text: 'headers.serviceName',
          align: 'start',
          sortable: true,
          value: 'service'
        }, {
          text: 'headers.fullName',
          align: 'start',
          sortable: true,
          value: 'fullName'
        }, {
          text: 'headers.title',
          align: 'start',
          sortable: true,
          value: 'title'
        }, {
          text: 'headers.preferredDate',
          align: 'start',
          sortable: true,
          value: 'preferredDate'
        }, {
          text: 'headers.preferredTime',
          align: 'start',
          sortable: true,
          value: 'preferredTime'
        }, {
          text: 'headers.preferredContactMethods',
          align: 'start',
          sortable: true,
          value: 'preferredContactMethods'
        }, {
          text: 'headers.creationDateTimeOnTwoRows',
          align: 'start',
          sortable: true,
          value: 'createdAt' //(yyyy-MM-dd HH:mm)
        }, {
          text: 'headers.lastChangeDateTimeOnTwoRows',
          align: 'start',
          sortable: true,
          value: 'updatedAt' //(yyyy-MM-dd HH:mm)
        }, {
          text: 'headers.status',
          align: 'start',
          sortable: true,
          value: 'status'
        }, {
          text: 'headers.details',
          align: 'start',
          sortable: true,
          value: 'details'
        }
      ],
      items: [],
      remoteSupportStatuses: REMOTE_SUPPORT_STATUS,
      totalElements: 0,
      size: 10,
      page: 1,
      isLoading: false,
      services: [],
      itemsEditableProps: []
    }
  },
  computed: {
    isEN() {
      return isLanguageEn()
    },
    getRole() {
      return getUserRole()
    },
    getExporter() {
      return exportRemoteSupportXlsx
    }
  },
  methods: {
    async tryToFetchRemoteSupport() {
      this.items = []
      this.itemsEditableProps = []
      const r = await execHttpRequestThrowable(async () => await fetchRemoteSupportByCriteria(this.size, this.page - 1, 'createdAt,DESC'),
      {},
      v => this.isLoading = v)

      this.pageable = r.response.data
      this.totalElements = this.pageable.totalElements
      this.pageable.content.forEach(o => {
        o.isDescriptionExpanded = false
        o.shortDescription = o.description.substring(0, 30).concat('...')
        o.statusObj = getObjectRemoteSupportStatusByKey(o.status)

        o.isEmailExpanded = false
      })

      this.items = this.pageable.content
      if (this.isRole("ADMIN")) {
        this.itemsEditableProps = this.items.map(o => {
          return {
            id: o.id,
            service: copyObj(o.service)
          }
        })
      }
    },
    async tryToFetchAllServices() {
      const r = await execHttpRequestThrowable(fetchAllServices, {}, v => this.isLoading = v)
      this.services = [...r.response.data]
    },
    async tryToChangeRequestService(requestId, service) {
      await execHttpRequestThrowable(async () => await changeRequestService(requestId, service.id),
      {},
      v => this.isLoading = v)

      if (this.isRole("ADMIN")) {
        this.itemsEditableProps.filter(o => o.id === requestId)[0].service = copyObj(service)
      }

      this.$snackbar.success('snackbar.success.serviceSaved')
    },
    openConfirmDialog(item) {
      this.$confirmActionDialog.open(
      'dialogs.titles.changeService', 
      null, 
      'btns.save',
      null,
      async () => await this.tryToChangeRequestService(item.id, item.service),
      () => {
        item.service = copyObj(this.itemsEditableProps.filter(o => o.id === item.id)[0].service)
      })
    },
    getDateNewYorkFormat(d) {
      return DateUtil.getDateNewYorkFormat(d)
    },
    isRole(roles) {
      return roles.includes(this.getRole)
    },
    getHeaders() {
      const headers = []

      for (const h of this.headers) {
        if (this.isRole(['CLIENT']) && h.value === 'clientName') continue
        if (!this.isRole(['ADMIN']) && h.value === 'service') continue
        headers.push(h)
      }

      return headers
    },
    getTimeZoneInNumber(d) {
      return DateUtil.getTimeZoneInNumber(d)
    },
    extractDate(d) {
      return DateUtil.breakDateNewYorkToDateAndTime(DateUtil.getDateNewYorkFormat(d)).date
    },
    extractTime(d) {
      return DateUtil.breakDateNewYorkToDateAndTime(DateUtil.getDateNewYorkFormat(d)).time
    },
     breakDateAndTimeLabels(dateTimeNewYorkFormatText) {
      const { date, time } = DateUtil.breakDateNewYorkToDateAndTime(dateTimeNewYorkFormatText)
      return `${date}<br/>${time}`
    },
    makePreferredContactMethodsPreviewable(methods) {
      let builder = ""

      for(let i = 0; i < methods.length; i++) {
        const m = methods[i]

        builder = builder.concat(this.$t(`pages.remoteSupport.preferredContactMethod.${m}`))
        if (i + 1 < methods.length) builder = builder.concat(", ")
      }

      return builder
    },
    isRequestDone(status) {
      return status === "COMPLETED" || status === "REJECTED" || status === "DONE"
    }
  },
  async mounted() {
    // if (!this.isRole(['CLIENT', 'SERVICE'])) routeFromRoot('active-repairs')

    await this.tryToFetchRemoteSupport()
    if (this.isRole("ADMIN")) await this.tryToFetchAllServices()

    this.$feedback.fill("Remote Support List Page", this.$route.path)
  }
}
</script>
<style>
.main-data-table.theme--light.v-data-table {
  background: #ffffff;
}
.main-data-table.v-data-table {
  background: transparent !important;
}
/*
Rows per page color!
.main-data-table .v-select__selection.v-select__selection--comma {
  color: #DF9811;
}
*/
.main-data-table.v-icon.notranslate .mdi.mdi-menu-down.theme--light.primary--text {
  color: #DF9811;
}
/* 
Rows per page underline color!
.main-data-table .v-select > .v-input__control > .v-input__slot{
  color: #DF9811;
}
*/
/*
Right arrow color! 
.main-data-table .v-btn--icon.v-size--default .v-icon {
  color: #DF9811;
}
*/
.main-data-table.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row) {
  border-color: transparent;
}

.main-data-table .v-data-table-header {
  background-color: #cfd8dc!important;
}
thead.v-data-table-header > tr > th.text-start {
  border-left: white 1px solid;
  border-right: white 1px solid;
}

.main-data-table-header {
  color: #000000;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
  letter-spacing: 1px;
}

.detail-btn.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light {
  color: #fff;
  background-color: #4285f4!important;
  border-color: #75a4f0;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  font-size: 0.65rem;
  letter-spacing: 1px;
}
</style>