export const en = {
  common: {
    rowsPerPage: 'Rows per page',
    clientAction: {
      home: {
        title: 'Home',
        subtitle: 'STS Home Page'
      },
      newRepairRequest: {
        title: 'New Repair Request',
        subtitle: 'Make a new request for parts/handhelds repair'
      },
      sparePartsRequest: {
        title: 'Spare Parts Request',
        subtitle: 'Make a new request for spare parts'
      },
      remoteSupportRequest: {
        title: 'Remote Support Request',
        subtitle: 'Make a new request for remote support'
      }
    },
    pressContinueWhenReady: 'When you are ready, press “Continue” so the process will move forward',
    serviceCategories: {
      'REPAIR': 'Repair Request',
      'PARTS': 'Part Request',
      'REMOTE_SUPPORT': 'Remote Support',
      "OTHERS": "Others"
    }
  },
  pages: {
    baseLayout: {
      systemTitle: 'STS - account {0}'
    },
    contact: {
      newContact: 'New User',
      users: 'Users',
      userList: 'Users List',
      profile: 'Profile',
      accountDetailsTitle: 'Account Details',
      addressDetailsTitle: 'Address Details',
      clientServiceDetailsTitle: '{0} Details',
      helloUser: 'Hello, {0}',
      userDetails: 'User Details',
      logout: 'Log out'
    },
    part: {
      parts: 'Parts',
      newPart: 'New Part',
      addPart: 'ADD PART',
      editPart: 'EDIT A PART'
    },
    repair: {
      newRepair: 'New Repair',
      actionRequired: 'Action Required',
      activeRepairs: 'Active Repairs',
      closedRepair: 'Closed Repairs',
      reports: 'Reports',
      generalReport: 'General Report',
      repairInProgress: 'REPAIR IS STILL IN PROGRESS!',
      repairCompleted: 'REPAIR IS COMPLETED!',
      completedRepairDetails: 'COMPLETED REPAIR DETAILS',
      expectDeliveryConfirmation: 'Expect delivery confirmation before {0} ("Estimate delivery + two days")',
      repairRequestDetails: 'REPAIR REQUEST DETAILS',
      partsForApproval: 'PARTS FOR APPROVAL',
      partsInfo: 'PARTS INFO',
      partsAreInService: 'Now the parts are in the repair service. The current status of each part can be found below:',
      partsAreInServiceOptional: 'Now {0} parts are in the repair service. The current status of each part can be found below:',
      partsAreInDiagnostic: 'Now your parts are undergoing diagnostics. When the diagnostics finish you will be notified about the results.',
      repairStatusChangeHistory: 'REPAIR STATUS CHANGE HISTORY',
      partsForRepairApproval: 'PARTS FOR REPAIR APPROVAL',
      partsAreInWaitingApproval: 'Now your parts are waiting approval for repair. The current status of each part can be found below:',
      partsInService: 'PARTS IN SERVICE',
      contactDetails: 'CONTACT DETAILS',
      addressDetails: 'ADDRESS DETAILS',
      packageDetails: 'PACKAGE DETAILS',
      addPackageInfo: 'Add information for every package. Add new package with the green button',
      pickUpDetails: 'PICK-UP DETAILS',
      transitBackShippingDetails: 'TRANSIT BACK SHIPPING DETAILS',
      startDiagnosticDate: 'START DIAGNOSTICS DATE',
      estimatedDiagnosticsDateBelow: 'Estimated diagnostics date can be found below:',
      dataAutoSavedLocally: 'Data is auto-saved locally',
      dontSendRejectedParts: 'Please check in the previous step, whether all your parts were approved. Please don\'t send parts which were not approved.',
      tabs: {
        details: 'Details',
        shippingDetails: 'Shipping Details',
        waitingForPickUp: 'Waiting for Pick Up',
        inTransit: 'In Transit',
        waitingDiagnostics: 'Waiting Diagnostics',
        diagnostics: 'Diagnostics',
        repairApproval: 'Repair Approval',
        repair: 'Repair',
        transitBack: 'Transit Back',
        completed: 'Completed',
        history: 'History'
      },
      toolTipRequiredTextFields: 'Fields with * must be filled in for all parts!',
      updateDataShippingDetailsTab: 'A courier company will be sent to you to take the parts/handhelds. Please update your POC and shipping address details, if necessary, and enter information about the packages you want to ship.',
      newRepairDescription: 'Please, select from the drop-down menus the parts that you want to have repaired, and describe their issues. Serial number is а required field. Enter “-“ or “N/A” if the part does not have a serial number. “Submit” button will become active when you fill the required fields.',
      waitingForPickUpDescription: 'Press Continue when you are completely ready',
      pleaseApproveOrRejectDetailTab: 'Please, approve or reject every item and press Continue when you are ready with all of them.',
      pleaseConfirmDeliveryCompletedTab: 'Please, press “Confirm Delivery” when you receive your repaired parts and handhelds back.',
      deleteRepair: 'Are you sure you want to delete this request?'
    },
    partRequest: {
      menu: 'Part Request',
      repairCompleted: 'PART REQUEST IS COMPLETED!',
      completedRepairDetails: 'COMPLETED PART REQUEST DETAILS',
      newPartRequest: 'New Part Request',
      partRequestList: 'Part Requests List',
      repairRequestDetails: 'PART REQUEST DETAILS',
      repairStatusChangeHistory: 'PART STATUS CHANGE HISTORY',
      pleaseApproveOrRejectDetailTab: 'Please, approve or reject every item and press Continue when you are ready with all of them.',
      iDontFindMyPart: 'If you do not find the part you are looking for, you can describe it by filling in the fields below:',
      deletePartRequest: 'Are you sure you want to delete this part request?',
      addressDetails: "Shipping address details"
    },
    remoteSupport: {
      menu: 'Remote Support',
      repairWaitingCompletion: 'REMOTE SUPPORT IS WAITING COMPLETION!',
      repairCompleted: 'REMOTE SUPPORT IS COMPLETED!',
      newRemoteSupport: 'New Request',
      remoteSupportList: 'Requests List',
      cardTitle: 'Create New Remote Support',
      toolTipClickToExpand: 'Click To Expand',
      toolTipClickToCollapse: 'Click To Collapse',
      repairRequestDetails: 'REMOTE SUPPORT DETAILS',
      dataForApproval: 'Support For Approval',
      pleaseApproveOrRejectDetailTab: 'Please, approve or reject the support and press Continue when you are ready.',
      repairStatusChangeHistory: 'REMOTE SUPPORT STATUS CHANGE HISTORY',
      reviewData: 'REVIEW DATA',
      tabs: {
        serviceReview: 'Support Review'
      },
      preferredContactMethod: {
        PHONE: "Phone",
        VIBER: "Viber",
        WHATS_APP: "WhatsApp",
        SKYPE: "Skype"
      }
    },
    requestAccount: {
      cardTitle: 'Requesting An Account',
      approvedCardTitle: 'Account Request Approved',
      rejectedCardTitle: 'Account Request Rejected'
    },
    mail: {
      settings: 'Settings',
      mailNotification: 'Mail Notification',
      settingRoleSubtitle: 'Use this screen to control mails sent to every role. The process is divided into steps. For every step you can determine which role to receive a mail notification for status change. You need to press Save after changes.'
    },
    stsDescription: {
      title: 'NSDD Parts Description',
      subtitle: 'NSDD Parts provides an on-line tool for NSDD Partner Countries to request handheld equipment repairs, radiation portal monitor part repairs, and other part support.'
    },
    stsDisclaimer: {
      title: 'Disclaimer',
      subtitle: 'All requests will be reviewed to determine if they can be contractually supported and are economical in scope.'
    },
    resetPassword: {
      cardTitle: 'Reset Password'
    }
  },
  textFields: {
    selectRole: 'Select Role',
    userPassword: `User's password`,
    changingPassword: 'Changing password!',
    companyName: 'Company Name',
    country: 'Country',
    state: 'State',
    city: 'City',
    postalCode: 'Postal code',
    street: 'Street',
    streetDetails: 'Street Details',
    building: 'Building Details',
    email: 'E-mail',
    secondaryEmail: 'Secondary E-mail',
    clientName: 'Client name',
    serviceName: 'Service name',
    username: 'Username',
    contactPerson: 'Contact person',
    contactEmail: 'Contact e-mail',
    address: 'Address',
    pricePerHour: 'Price per hour (USD)',
    noData: 'No Data',
    requestId: 'Request ID',
    countryCode: 'Country Code',
    status: 'Status',
    selectStatus: 'Select Status',
    changeStatus: 'Change Status',
    creationDateTime: 'Creation Date/Time ({0})',
    lastChangeDateTime: 'Last Change Date/Time ({0})',
    creationDateTimeUTC: 'Creation Date/Time (UTC)',
    lastChangeDateTimeUTC: 'Last Change Date/Time (UTC)',
    dimensions: 'Dimensions D/W/H (cm)',
    weight: 'Weight (kg)',
    courierName: 'Courier Name',
    pickUpDate: 'Pick Up Date',
    estimateDeliveryDate: 'Estimate Delivery Date',
    trackingNumber: 'Tracking Number',
    courierArrivingTime: 'Courier Arriving Time',
    note: 'Note',
    optional: '(Optional)',
    notSetYet: 'Not set yet!',
    diagnosticDate: 'Diagnostics Date',
    clientLabel: 'Client:',
    statusLabel: 'Status:',
    serviceLabel: 'Service:',
    categoryLabel: 'Category:',
    partLabel: 'Part:',
    requestNumberLabel: 'Request number:',
    serialNumberLabel: 'Serial Number:',
    serviceTagLabel: 'Service Tag:',
    creationDateFromLabel: 'Creation Date From:',
    creationDateToLabel: 'Creation Date To:',
    searchLabel: 'Search:',
    setNameOrValueProperty: `Set 'name' or 'value' property!`,
    firstName: 'First Name',
    lastName: 'Last Name',
    yourPassword: 'Your password',
    confirmPassword: 'Confirm your password',
    newPassword: 'New Password',
    phone: 'Phone number',
    yourUsername: 'Your username',
    locked: 'Locked',
    unlocked: 'Unlocked',
    selectCategory: 'Select Category',
    partNumber: 'Part Number',
    partModel: 'Part Model',
    manufacturer: 'Manufacturer',
    shortDescription: 'Short Description',
    uploadYourFile: 'Upload your file',
    itemCategoryLabel: 'Item Category:',
    quantityLabel: 'Quantity:',
    clients: 'Clients',
    partName: 'Part Name',
    title: 'Title',
    description: 'Description',
    preferredDateUTC: 'Preferred Date (UTC)',
    preferredTimeUTC: 'Preferred Time (UTC)',
    preferredDate: 'Preferred Date ({0})',
    preferredTime: 'Preferred Time ({0})',
    backupEmailNo: 'Backup E-mail ({0})',
    removed: 'Removed',
    clientNo: 'Client ({0})',
    approval: 'Approval',
    dimensionsNo: 'Dimensions ({0})',
    weightNo: 'Weight ({0})',
    diagnosticStartDate: 'Diagnostics Start Date',
    content: 'Content',
    enabledDisabled: 'Enable/Disable',
    enabled: 'Enabled',
    disabled: 'Disabled',
    serviceCategories: 'System Categories',
    durationInHours: 'Duration In Hours',
    completionDate: 'Completion date ({value})',
    summary: 'Summary',
    specialSupport: "Is it related to PSI, RSI or Kromek equipment?",
    doesServiceSupportSpecialServices: "Support PSI, RSI and Kromek equipment",
    simpleYes: "Yes",
    simpleNo: "No",
    selectAnAnswer: "Please indicate by selecting",
    preferredContactMethod: "Preferred Contact Method:"
  },
  placeholder: {
    selectClient: 'Select Client',
    selectStatus: 'Select Status',
    selectService: 'Select Service',
    selectCategory: 'Select Category',
    requestNumber: 'Request number',
    serialNumber: 'Serial Number',
    serviceTag: 'Service Tag',
    selectPart: 'Select Part'
  },
  btns: {
    create: 'Create',
    save: 'Save',
    saveAll: 'Save All',
    close: 'Close',
    request: 'Request',
    applyChanges: 'Apply changes',
    submitRequest: 'Submit request',
    ok: 'OK',
    confrimDelivery: 'Confirm Delivery',
    reject: 'Reject',
    approve: 'Approve',
    shipmentArrived: 'Shipment arrived',
    allInProgress: 'All In Progress',
    allReplaced: 'All Replaced',
    allRepaired: 'All Repaired',
    allNonRepairable: 'All Non-Repairable',
    rejectAll: 'Reject All',
    approveAll: 'Approve All',
    approveMarked: 'Approve Marked',
    submitForApproval: 'Submit for approval',
    continue: 'Continue',
    setDiagnosticDate: 'Set Diagnostics Date',
    packagesPickedUp: 'The packages are picked up',
    search: 'Search',
    reset: 'Reset',
    cancel: 'Cancel',
    edit: 'Edit',
    backToList: 'Back to the list',
    viewDetails: 'View Details',
    addPart: 'ADD PART',
    delete: 'Delete',
    deleteAll: 'Delete All',
    exportXLSX: 'Export to XLSX',
    chooseFile: 'Choose File',
    newRepairRequest: 'New Repair Request',
    remoteSupport: 'Remote Support',
    newPartRequest: 'New Part Request',
    home: 'Home',
    send: 'Send',
    complete: 'Complete',
    addEmail: 'Add E-mail',
    continueEditing: 'Continue Editing'
  },
  roles: {
    client: 'Client',
    service: 'Service',
    supervisor: 'Supervisor',
    admin: 'Admin',
    sustainabilityManager: 'Sustainability Manager'
  },
  invalidValue: {
    required: 'Required!',
    _2LettersOnly: 'The value must contains 2 letters only!',
    _2LettersAtLeast: 'The value must contains 2 letters at least!',
    _10DigitsAtLeast: 'The value must contains 10 digits at least!',
    invalidDimensions: 'The value must be in the specified template: Exp - 100/100/100!',
    invalidEmail: 'Invalid email!',
    valueMustBeDigit: 'The value must be digit!',
    invalidPassword: 'The value must contains 8 symbols at least, 1 digin at least, 1 capital letter at least, 1 special symbol at least!',
    passwordNotMatched: 'The passwords do not match',
    maxNumberRestriction: 'The number must be smaller than or equal to {0}',
    maxSymbols: 'The number of characters must be up to {value}',
    minymbols: 'The number of characters must be at least {value}',
    invalidUsername: 'Username can only contain letters(Latin and Cyrillic), numbers and an underscore'
  },
  dialogs: {
    titles: {
      success: 'Success',
      failed: 'Failed',
      changes: 'Changes',
      forgottenPassword: 'Reset Password',
      retrieveUsername: 'Retrieve Username',
      clientAction: 'Please choose one action',
      validate: 'Validate',
      delete: 'Delete',
      imagePreview: 'Preview image {value}',
      changeService: 'Are you sure you want to change the service?',
      createRemoteSupportRequest: 'Are you sure you want to create a new Remote Support Request?',
      completeRemoteSupportRequest: "Completing the request"
    },
    subtitles: {
      createRepairWarning: 'The repair request cannot be modified after you submit it. Did you add all parts for repair and the description of their issues?',
      deletingItemOfNewRepairWarning: 'This is going to delete the item. Are you sure?',
      deletingItemsOfNewRepairWarning: 'This is going to delete the items. Are you sure?',
      requestNewPartWarning: 'The parts request cannot be changed once you submit it. Have you added all request parts?',
      aboutToCompleteRemoteSupportRequest: "Are you sure you described and filled out everything in the request?"
    },
    messages: {
      success: {
        userDataSavedSuccessfully: 'You successfully saved the user data - {0}',
        addressDataSavedSuccessfully: 'You successfully saved address data',
        clientDataSavedSuccessfully: 'You successfully saved client data',
        serviceDataSavedSuccessfully: 'You successfully saved service data',
        confirmedPackageReceivedBackSuccessfully: 'You successfully confirmed packages were received back!',
        firstDetailsCompleted: 'Your decision is sent successfully. Now is time for the Client and the Repair Service to arrange the transport.',
        repairStatusSetToRejected: 'Your decision is sent successfully. The request is now in status Completed.',
        approvalDecisionSaved: 'You successfully saved your approval decision!',
        rejectionDecisionSaved: 'You successfully saved your rejection decision!',
        dataSavedSuccessfully: 'Successfully saved data!',
        toWaitingDiagnosticsStep: `You success change status to 'WAITING DIAGNOSTICS'!`,
        toWaitingForPickUpStep: `You success change status to 'WAITING FOR PICK UP'!`,
        toTransitBackStep: `You success change status to 'TRANSIT BACK'!`,
        toWaitingCompletion: `You success change status to 'WAITING COMPLETION'!`,
        toDiagnostics: `You success change status to 'DIAGNOSTICS'!`,
        toTransportArranged: `You success change status to 'TRANSPORT ARRANGED'!`,
        toTransit: `You success change status to 'TRANSIT'!`,
        toSettingTrackingNumber: `You success change status to 'SETTING TRACKING NUMBER'!`,
        proceedingToNextStepSuccessfully: 'You are successfully proceeding to next step!',
        repairStatusSavedSuccessfully: 'You successfully saved repair status!',
        repairStatusesSavedSuccessfully: 'You successfully saved repair statuses!',
        pickUpDataSavedSuccessfully: 'You successfully saved pick up data!',
        serviceReviewDataSavedSuccessfully: 'You successfully saved support review data!',
        serviceReviewSummarySavedSuccessfully: 'You successfully saved support review summary!',
        transitBackDataSavedSuccessfully: 'You successfully saved transport-back arranging data!',
        diagnosticDateSavedSuccessfully: 'You successfully set diagnostics date!',
        transportArrangingDateSavedSuccessfully: 'You successfully saved transport arranging data!',
        partSavedSuccessfully: 'Successfully saved the part!',
        mailNotificationSettingsSavedSuccessfully: 'Successfully saved mail notification data!',
        resetPasswordMailSend: 'A password reset link has been sent to the email addresses you requested!',
        requestUsernameMailSend: 'An email has been sent to you with the usernames associated with your email!',
        requestAccountMailSend: 'Emails have been sent to the supervisors regarding your request. If your request is approved and confirmed, you will be sent an email with information about your new account!',
        feedbackRequestedSuccessfully: 'Feedback requested successfully!',
        repairDeletedSuccessfully: 'Repair deleted successfully!',
        partRequestDeletedSuccessfully: 'Part request deleted successfully!',
        remoteSupportFirstDetailsCompleted: 'Your decision is sent successfully. Now is time for the Repair Service to review the request.',
        completeRemoteSupportRequest: 'The request is completed!',
        waitingCompletion: 'The request should now be complete!'
      },
      failed: {
        usernameAlreadyExists: 'Contact with this username already exists!',
        actionNotProvided: 'An action is not provided! {0}',
        mailNotificationSettingsSavingError: 'Error while saving mail notification settings!',
        userNotFound: 'Cannot find a user with the given credentials',
        userNotFoundUsernameAndPassword: 'Username/password do not match',
        userNotFoundByUsername: 'Cannot find a user with the given username',
        usernameNotFoundByEmail: 'Cannot find a username with the given email!',
        internalErrorOccurred: 'An error occurred in the browser!',
        serverErrorOccurred: 'An error occurred on the server',
        badRequest: 'The request was not properly sent to the server!',
        accessForbidden: 'You have no authority for this service!',
        resourceNotFound: 'The requested information was not found!',
        methodNotAllowed: 'The request does not use the correct method that is expected!',
        actionKeyExpired: 'The request has expired!',
        actionKeyNotFound: 'No generated key was found for your request!',
        noLongerLoggedInTheSystem: 'Your session has expired, please log in again',
        clientWithNameAlreadyExists: 'CLIENT with this name already exists!',
        serviceWithNameAlreadyExists: 'SERVICE with this name already exists!',
        maxUploadSizeExceeded: 'Exceeded 1MB file size limit that can be uploaded!'
      }
    }
  },
  snackbar: {
    success: {
      serviceCategoriesSavedSuccessfully: 'Service categories saved successfully',
      initialApprovalSaved: 'Initial approval status "{value}" saved!',
      serviceSaved: "The service has been saved successfully!"
    }
  },
  initialStatuses: {
    approved: 'Approved',
    rejected: 'Rejected',
    waitingApproval: 'Waiting Approval'
  },
  repairStatus: {
    itIsWorking: 'It is working',
    forRepair: 'For repair',
    forReplace: 'For replace',
    nonRepairable: 'Non-Repairable'
  },
  serviceStatus: {
    inProgress: 'In progress',
    replaced: 'Replaced',
    repaired: 'Repaired',
    nonRepairable: 'Non-Repairable',
    rejected: 'Rejected'
  },
  diagnosticsStatuses: {
    itIsWorking: 'It is working',
    needsRepairing: 'Needs repairing',
    needsReplacing: 'Needs replacing',
    nonRepairable: 'Non-Repairable'
  },
  partRequestStatuses: {
    initial: 'Waiting Approval',
    done: 'Delivered',
    inOneWayShipping: 'Arranging Transport',
    inWaitingForPickUp: 'Waiting Courier',
    waitingConfirmation: 'Shipped',
    rejected: 'Rejected'
  },
  remoteSupportStatuses: {
    initial: 'Waiting Approval',
    serviceReview: 'Arranging Remote Support',
    waitingCompletion: 'Remote Support Review',
    rejected: 'Rejected',
    done: 'Done'
  },
  mainStatuses: {
    none: 'NONE',
    new: 'NEW',
    inShippingDetails: 'SHIPPING DETAILS',
    settingTrackingNumber: 'SETTING TRACKING NUMBER',
    inWaitingForPickUp: 'WAITING FOR PICK UP',
    inPickUpArranged: 'TRANSPORT ARRANGED',
    transit: 'TRANSIT',
    inWaitingDiagnostics: 'WAITING DIAGNOSTICS',
    inDiagnostics: 'DIAGNOSTICS',
    inRepairApproval: 'REPAIR APPROVAL',
    inService: 'SERVICE',
    inTransitBack: 'TRANSIT BACK',
    inWaitingCompleted: 'WAITING COMPLETION',
    completed: 'COMPLETED',
    rejected: 'REJECTED'
  },
  mailActionTypes: {
    changeRemoteSupportRequestService: "Change Remote Support request service"
  },
  headers: {
    mark: 'Mark',
    image: 'Image',
    category: 'Category',
    partName: 'Part Name',
    serialNumber: 'Serial Number',
    serviceTag: 'Service Tag',
    problemDescription: 'Problem Description',
    comment: 'Comment',
    repairComment: 'Repair Comment',
    initialApproval: 'Status',
    status: 'Status',
    supervisorStatus: 'Supervisor Status',
    approval: 'Approval',
    repairStatus: 'Repair Status',
    repairApproval: 'Repair Approved',
    needHours: 'Hours Needed',
    totalCost: 'Total Cost (USD)',
    sparePartPrice: 'Spare Parts Price (USD)',
    diagnosticPrice: 'Diagnostic Price (USD)',
    repairPrice: 'Repair Price (USD)',
    partsPrice: 'Parts Price (USD)',
    diagnosticPriceTotal: 'Total Diagnostic Price (USD)',
    repairPriceTotal: 'Total Repair Price (USD)',
    partsPriceTotal: 'Total Parts Price (USD)',
    totalCostOfAll: 'Total Total Cost (USD)',
    totalPrice: 'Total Price (USD)',
    date: 'Date ({0})',
    dateUTC: 'Date (UTC)',
    changedBy: 'Changed By',
    role: 'Role',
    oldStatus: 'Old status',
    newStatus: 'New status',
    action: 'Action',
    userId: 'User ID',
    username: 'Username',
    details: 'Details',
    itemCategory: 'Item Category',
    partNumber: 'Part Number',
    model: 'Model',
    manufacturer: 'Manufacturer',
    description: 'Description',
    title: 'Title',
    requestId: 'Request ID',
    creationDateTimeOnTwoRows: 'Creation Date/Time <br/> ({0})',
    lastChangeDateTimeOnTwoRows: 'Last Change Date/Time <br/> ({0})',
    creationDateTime: 'Creation Date/Time ({0})',
    lastChangeDateTime: 'Last Change Date/Time ({0})',
    creationDateTimeUTC: 'Creation Date/Time (UTC)',
    lastChangeDateTimeUTC: 'Last Change Date/Time (UTC)',
    clientName: 'Client Name',
    serviceName: 'Service Name',
    actionRequired: 'Action Required',
    completionDate: 'Completion date ({0})',
    completionDateUTC: 'Completion date (UTC)',
    deleteRow: 'Delete Row',
    client: 'Client',
    service: 'Service',
    supervisor: 'Supervisor',
    sustainabilityManager: 'Sustainability Manager',
    admin: 'Administrator',
    oldValue: 'Old Value',
    newValue: 'New Value',
    row: 'Row',
    number: '#',
    quantity: 'Quantity',
    parts: 'Parts',
    fullName: 'Full Name',
    phone: 'Phone',
    email: 'E-mail',
    addRemovePackage: 'Add/Remove Package',
    field: 'Field',
    enabledDisabled: 'Enable/Disable',
    serviceCategories: 'System Categories',
    preferredDate: 'Preferred Date ({0})',
    preferredTime: 'Preferred Time ({0})',
    preferredContactMethods: "Preferred Contact Methods"
  },
  other: {
    show: 'Show',
    showing: 'Showing',
    to: 'to',
    of: 'of',
    entries: 'entries',
    from: 'From',
    forgottenCredentials: 'Forgotten Credentials',
    forgottenPasswordLink: 'Reset Password',
    retrieveUsername: 'Retrieve Username',
    accountRequestLink: 'Request a new account',
    months: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December'
    }
  },
  export: {
    title: {
      activeRepairs: 'Active Repairs',
      closedRepairs: 'Closed Repairs',
      generalReport: 'General Report',
      actionRequiredRepairs: 'Action Required Repairs',
      users: 'Users',
      parts: 'Parts',
      partRequest: 'Part Request',
      remoteSupport: 'Remote Support',
      repairDetailTab: 'Repair Detail Tab',
      repairDiagnosticsTab: 'Repair Diagnostics Tab',
      repairApprovalTab: 'Repair Approval Tab',
      repairTab: 'Repair Tab',
      repairHistoryTab: 'Repair History Tab',
      partRequestDetailTab: 'Part Request Detail Tab',
      partRequestHistoryTab: 'Part Request History Tab',
      remoteSupportHistoryTab: 'Remote Support History Tab'
    }
  },
  toolTips: {
    preview: 'Preview'
  }
}