<template>
  <v-autocomplete
  v-if="!normal"
  v-model="model"
  :rules="rules"
  :label="label"
  :placeholder="placeholder"
  :item-text="itemText"
  :item-value="itemValue"
  return-object
  :auto-select-first="autoSelectFirst"
  hide-details="auto"
  :items="items"
  :disabled="disabled"
  :clearable="clearable"
  @input="emitInput">
    <template v-slot:[`selection`]="{ item }">
      <span v-if="item.value">{{$t(item.value)}}</span>
      <span v-else-if="item.name">{{$t(item.name)}}</span>
    </template>
    <template v-slot:[`item`]="{ item }">
      <span v-if="item.value">{{$t(item.value)}}</span>
      <span v-else-if="item.name">{{$t(item.name)}}</span>
    </template>
  </v-autocomplete>
  <v-autocomplete
  v-else
  v-model="model"
  :rules="rules"
  :label="label"
  :placeholder="placeholder"
  class="normal-text-field"
  :items="items"
  :item-text="itemText"
  :item-value="itemValue"
  :auto-select-first="autoSelectFirst"
  return-object
  :disabled="disabled"
  flat
  solo
  dense
  hide-details="auto"
  :clearable="clearable"
  @input="emitInput">
    <template v-slot:[`selection`]="{ item }">
      <span v-if="item.value">{{$t(item.value)}}</span>
      <span v-else-if="item.name">{{$t(item.name)}}</span>
    </template>
    <template v-slot:[`item`]="{ item }">
      <span v-if="item.value">{{$t(item.value)}}</span>
      <span v-else-if="item.name">{{$t(item.name)}}</span>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    label: String,
    placeholder: String,
    items: Array,
    value: Object,
    required: Boolean,
    normal: Boolean,
    itemText: String,
    itemValue: String,
    disabled: Boolean,
    autoSelectFirst: Boolean,
    clearable: {
      type: Boolean,
      default: true
    }
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  data() {
    return {
      rules: [
        (v) => this.required ? !!v || this.$t('invalidValue.required') : true
      ]
    }
  },
  methods: {
    emitInput() {
      this.$emit("input")
    }
  }
}
</script>
<style>

</style>