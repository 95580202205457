import { app } from '@/main'

const SYSTEM_USER_DATA = 'STS_WEB_SYSTEM_USER_DATA'
const SYSTEM_LANG_NAME = 'sts-lang'
const SYSTEM_ACCESS_PROPERTIES_NAME = 'accessProperties'


export function getUserIdentification() {
  return localStorage.getItem(SYSTEM_USER_DATA) != null ? JSON.parse(localStorage.getItem(SYSTEM_USER_DATA)).identification : null
}
export function getUserToken() {
  return localStorage.getItem(SYSTEM_USER_DATA) != null ? JSON.parse(localStorage.getItem(SYSTEM_USER_DATA)).token : null
}
export function getUserTokenId() {
  return localStorage.getItem(SYSTEM_USER_DATA) != null ? JSON.parse(localStorage.getItem(SYSTEM_USER_DATA)).id : null
}
export function getUserRole() {
  return localStorage.getItem(SYSTEM_USER_DATA) != null ? JSON.parse(localStorage.getItem(SYSTEM_USER_DATA)).permissions[0] : null
}
export function isUserLogged() {
  const m = new RegExp(/STS_LOGGED=(?<value>\w+)/).exec(document.cookie)
  if (m) return m[1].toLowerCase() === 'true' 
  return false
}
export function setUserToken(token) {
  localStorage.setItem(SYSTEM_USER_DATA, JSON.stringify(token))
  app.$auth.process()
}
export function setUserIdentification(i) {
  const rawT = localStorage.getItem(SYSTEM_USER_DATA)
  if (rawT) {
    const parseT = JSON.parse(rawT)
    parseT.identification = i
    localStorage.setItem(SYSTEM_USER_DATA, JSON.stringify(parseT))
  }
  else return null
}
export function setUserSecret(s) {
  const rawT = localStorage.getItem(SYSTEM_USER_DATA)
  if (rawT) {
    const parseT = JSON.parse(rawT)
    parseT.secret = s
    localStorage.setItem(SYSTEM_USER_DATA, JSON.stringify(parseT))
  }
  else return null
}
export function removeUserToken() {
  localStorage.removeItem(SYSTEM_USER_DATA)
  //NOTE: delete /STS_LOGGED/ cookie
  document.cookie = "STS_LOGGED=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  app.$auth.process()
}
export function updateUserTokenByEmail(email) {
  const token = localStorage.getItem(SYSTEM_USER_DATA) != null ? JSON.parse(localStorage.getItem(SYSTEM_USER_DATA)) : null
  if (!token) return false
  token.identification = email
  setUserToken(token)

  return true
}
export function selectLanguage(lang) {
  if (lang.toLowerCase() === 'es' || lang.toLowerCase() === 'en') localStorage.setItem(SYSTEM_LANG_NAME, lang)
  else console.error("There no such language!")
}
export function getSelectedLanguage() {
  if (localStorage.getItem(SYSTEM_LANG_NAME) === null) {
    localStorage.setItem(SYSTEM_LANG_NAME, 'en')
    return 'en'
  }
  else return localStorage.getItem(SYSTEM_LANG_NAME)
}
export function getSelectedLanguageFromUrl() {
  return window.location.hash.substr(2, 2)
}
export function isLanguageSp() {
  return getSelectedLanguage().toLowerCase() === 'es'
}
export function isLanguageEn() {
  return getSelectedLanguage().toLowerCase() === 'en'
}
export function getAccessProperties() {
  return localStorage.getItem(SYSTEM_ACCESS_PROPERTIES_NAME) != null ? JSON.parse(localStorage.getItem(SYSTEM_ACCESS_PROPERTIES_NAME)) : null
}
export function setAccessProperties(props) {
  localStorage.setItem(SYSTEM_ACCESS_PROPERTIES_NAME, JSON.stringify(props))
}
export function removeAccessProperties() {
  localStorage.removeItem(SYSTEM_ACCESS_PROPERTIES_NAME)
}