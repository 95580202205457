<template>
  <v-container 
  v-if="isDataReady"
  fluid>
    <updatable-status-by-admin
    title="pages.remoteSupport.repairRequestDetails"
    :subtitle="$parent.isRole(['ADMIN', 'SUPERVISOR']) ? 'pages.remoteSupport.pleaseApproveOrRejectDetailTab' : ''"
    :data="remoteSupportProp"
    :loading="isLoading"
    :statusesFetcher="getPartRequestStatuses"
    :statusGetter="getStatusAsObject"/>
    <v-row dense class="mt-10">
      <v-col>
        <span class="process-repair-tab-section-title text-uppercase">{{$t('pages.remoteSupport.dataForApproval')}}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <new-remote-support
        :preview-data="newData"/>
      </v-col>
    </v-row>
    <v-row 
    v-if="$parent.isRole(['ADMIN', 'SUPERVISOR'])"
    class="mt-5"
    dense>
      <v-col>
        <process-repair-quick-btns
        :config="getQuickBtnsConfig"
        :loading.sync="isLoading"
        title="common.pressContinueWhenReady"
        on-success-repair-status-message="dialogs.messages.success.remoteSupportFirstDetailsCompleted"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ProcessRepairQuickBtns from '@/components/process-repair-tabs/ProcessRepairQuickBtns'
import UpdatableStatusByAdmin from '@/components/common/process-tabs/UpdatableStatusByAdmin'
import NewRemoteSupport from '@/pages/remote-support/NewRemoteSupport'
import { getObjectRemoteSupportStatusByKey, getAvailableRemoteSupportStatuses,  } from '@/utils/statusesUtil'
import { execHttpRequestThrowable } from '@/services/http'
import { updateInitialApproval } from '@/repositories/supportRepo'
import { copyObj } from '@/utils/StringUtil'
import { getTime } from '@/utils/DateUtil'
export default {
  name: 'DetailsTabRemoteSupport',
  components: {
    ProcessRepairQuickBtns,
    UpdatableStatusByAdmin,
    NewRemoteSupport
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  data() {
    return {
      newData: null,
      isLoading: false,
      isDataReady: false
    }
  },
  computed: {
    remoteSupportProp: {
      get: function() {
        return this.$props.data
      },
      set: function(v) {
        this.$emit('update:data', v)
      }
    },
    getQuickBtnsConfig() {
      return [
        {
          name: 'Reject',
          events: {
            'click': () => this.tryToChangeInitialStatus('REJECTED')
          },
          props: {
            'disabled': this.disabled || this.newData.initialApproval === 'REJECTED'
          }
        }, {
          name: 'Approve',
          events: {
            'click': () => this.tryToChangeInitialStatus('APPROVED')
          },
          props: {
            'disabled': this.disabled || this.newData.initialApproval === 'APPROVED'
          }
        }, {
          name: 'Continue',
          props: {
            'disabled': this.disabled || !this.newData.isAllowedToContinue
          }
        }
      ]
    }
  },
  methods: {
    async tryToChangeInitialStatus(status) {
      const r = await execHttpRequestThrowable(async () => await updateInitialApproval(this.$route.params.id, status), {}, v => this.isLoading = v)
      this.newData.initialApproval = String(status)
      this.remoteSupportProp.initialApproval = String(status)
      this.newData.isAllowedToContinue = r.response.data
      this.$snackbar.success('snackbar.success.initialApprovalSaved', {value: status})
    },
    getPartRequestStatuses() {
      return getAvailableRemoteSupportStatuses()
    },
    getStatusAsObject(s) {
      return getObjectRemoteSupportStatusByKey(s)
    }
  },
  mounted() {
    this.newData = copyObj(this.remoteSupportProp)
    this.newData.isAllowedToContinue = this.remoteSupportProp.allowedToContinue
    this.newData.preferredTime = getTime(new Date(this.newData.preferredDate))
    this.newData.preferredDate = this.newData.preferredDate.replaceAll(/T.*$/g, '')
    this.isDataReady = true
  }
}
</script>
<style>

</style>