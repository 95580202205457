const service = {
  isEqual: (a, b) => {
    if ((a == null && b === '') ||
    (a === '' && b === null) ||
    (a === null && b === null) ||
    (a === '' && b === '')) {
      return true
    }
  
    return a === b
  },
  getCopies: (props, obj) => {
    const newObj = {}

    for (const p of props) {
      newObj[p] = obj[p]
    }

    return newObj
  },
  ifEmpty: (o, a) => {
    if (!o || o.length === 0) a()
  }
}
export default service

export function copyObj(o) {
  const newObject = {}
  deepCopy(o, newObject)

  return newObject
}
export function cloneObj(from, to) {
  deepCopy(from, to)
}
function deepCopy(old, _new) {
  for (const [k, v] of Object.entries(old)) {
    switch(typeof(v)) {
      case 'object': {
        const isNull = v === null || v === undefined

        if (!isNull) {
          if (Array.isArray(v)) _new[k] = copyArray(v)
          else {
            const ob = {}
            deepCopy(v, ob)
            _new[k] = ob
          }
        }
        else _new[k] = null
      }
      break
      case 'string': {
        _new[k] = String(v)
      }
      break
      case 'number': {
        _new[k] = Number(v)
      }
      break
      case 'boolean': {
        _new[k] = Boolean(v)
      }
      break
    }
  }
}
function copyArray(arr) {
  const newArr = []
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    let _new = null
    switch(typeof(item)) {
      case 'object': {
        if (Array.isArray(item)) _new = copyArray(item)
        else {
          const ob = {}
          deepCopy(item, ob)
          _new = ob
        }
      }
      break
      case 'string': {
        _new = String(item)
      }
      break
      case 'number': {
        _new = Number(item)
      }
      break
      case 'boolean': {
        _new = Boolean(item)
      }
      break
    }

    newArr[i] = _new
  }

  return newArr
}

export function compareObjects(old, _new, props) {
  return compareArrays([old], [_new], props, false)
}

export function compareArrays(old, _new, props, isRowNumber) {
  let items = []
  const number = old.length > _new.length ? old.length : _new.length

  let rowNumber = 1

  for(let y = 0; y < number; y++) {
    let OVERRIDEN_ROW_NUMBER = old[y] ? old[y]['rowNumber'] : null
    for (let i = 0; i < props.length; i++) {
      const f = props[i].field
      const g = props[i].getter
      let oldItem = null
      let newItem = null

      const match = new RegExp(/^.+\[]\.(?<arrayProp>.+)$/).exec(props[i].prop)

      const childerProps = match ? props[i].prop.replace(/\[].+/, '').split('.') : props[i].prop.split('.')
      
      oldItem = old[y] ? getObjectByProps(old[y], childerProps) : null
      newItem = _new[y] ? getObjectByProps(_new[y], childerProps) : null
      
      if (match) {
        items = [...items, ...compareArrays(oldItem, newItem, [{
          field: f,
          prop: match[1]
        }], true)]
      }
      else {
        //If the object has 'rowNumber' property, it's overriding here!
        const rowNumberConst = OVERRIDEN_ROW_NUMBER ? OVERRIDEN_ROW_NUMBER : (isRowNumber ? rowNumber : -1)
        
        let field = f
        if (typeof(f) === 'object') {
          field = {
            value: f.value,
            params: {
              '0': rowNumberConst
            }
          }

          if (f.params) {
            for (const [k, v] of Object.entries(f.params)) {
              field.params[k] = v
            }
          }
        }
        if (oldItem && newItem) {
          //TODO: this is for comparing byte arrays
          // if (!service.isEqual(oldItem, newItem) || (props[i].overridenCondition && await props[i].overridenCondition())) 
          if (!service.isEqual(oldItem, newItem)) items.push({
            number: rowNumberConst,
            field,
            newValue: g ? g(newItem) : newItem
          })
        }
        else if (oldItem) {
          if (!service.isEqual(oldItem, newItem)) items.push({
            number: rowNumberConst,
            field,
            newValue: 'textFields.removed'
          })
        }
        else {
          if (!service.isEqual(oldItem, newItem)) items.push({
            number: rowNumberConst,
            field,
            newValue: g ? g(newItem) : newItem
          })
        }
      }
    }
    ++rowNumber
  }

  return items
}

function getObjectByProps(o, props) {
  let newObj = o[props[0]]
  if (!newObj) return null
  for (let i = 1; i < props.length; i++) {
    newObj = newObj[props[i]]
    if (newObj === null) break
  }

  return newObj
}