<template>
  <v-container fluid>
    <v-row dense>
        <v-col>
          <p class="process-repair-tab-section-title">{{$t('pages.remoteSupport.repairStatusChangeHistory')}}</p>
        </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <main-data-table
        number-pages
        :headers="headers"
        :items="items"
        :page.sync="page"
        :size.sync="size"
        :loading="isLoading"
        :onUpdateSize="tryToFetchHistoryByCriteria"
        :onPerviousBtnClick="tryToFetchHistoryByCriteria"
        :onNextBtnClick="tryToFetchHistoryByCriteria"
        :total-elements="totalElements">
          <template v-slot:[`header.createdAt`]="{ header }">
              {{$t(header.text, {'0': getTimeZoneInNumber(new Date())})}}
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
              <span>
                {{getDateNewYorkFormat(item.createdAt)}}
              </span> 
          </template>
          <template v-slot:[`item.oldStatus`]="{ item }">
              <span>
                {{$t(getStatus(item.oldStatus).value)}}
              </span>
          </template>
          <template v-slot:[`item.newStatus`]="{ item }">
              <span>
                {{$t(getStatus(item.newStatus).value)}}
              </span>
          </template>
        </main-data-table>
      </v-col>
    </v-row>
    <export-xlsx-row
    :width="150"
    :height="50"
    :disabled="items.length === 0"
    :loading.sync="isLoading"
    class="px-4 mt-4"
    name="export.title.remoteSupportHistoryTab"
    :exporter="getExporter"/>
  </v-container>
</template>
<script>
import DateUtil from '@/utils/DateUtil'
import { fetchRemoteSupportHistoryStatusByCriteria } from '@/repositories/supportRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { getObjectRemoteSupportStatusByKey } from '@/utils/statusesUtil'
import { exportRemoteSupportHistoryTabXlsx } from '@/repositories/exportRepo'
import ExportXlsxRow from '@/components/common/ExportXlsxRow'
import MainDataTable from '@/components/common/MainDataTable'
export default {
  name: 'HistoryTabRemoteSupport',
  components: {
    MainDataTable,
    ExportXlsxRow
  },
  data() {
    return {
      headers: [
        {
          text: 'headers.date',
          align: 'start',
          sortable: true,
          value: 'createdAt'
        },{
          text: 'headers.changedBy',
          align: 'start',
          sortable: true,
          value: 'changedBy'
        },{
          text: 'headers.role',
          align: 'start',
          sortable: true,
          value: 'role'
        },{
          text: 'headers.oldStatus',
          align: 'start',
          sortable: true,
          value: 'oldStatus'
        }, {
          text: 'headers.newStatus',
          align: 'start',
          sortable: true,
          value: 'newStatus'
        }
      ],
      items: [],
      size: 10,
      page: 1,
      totalElements: 0,
      isLoading: false
    }
  },
  computed: {
    getExporter() {
      return async () => await exportRemoteSupportHistoryTabXlsx(this.$route.params.id)
    }
  },
  methods: {
    getDateNewYorkFormat(d) {
      return DateUtil.getDateNewYorkFormat(d)
    },
    getStatus(k) {
      return getObjectRemoteSupportStatusByKey(k)
    },
    async tryToFetchHistoryByCriteria() {
      const r = await execHttpRequestThrowable(async () => await fetchRemoteSupportHistoryStatusByCriteria(this.$route.params.id, this.size, this.page - 1, 'createdAt,ASC'), {},
      v => this.isLoading = v)
      const p = r.response.data
      this.totalElements = p.totalElements
      this.items = [...p.content]
    },
    getTimeZoneInNumber(d) {
      return DateUtil.getTimeZoneInNumber(d)
    }
  },
  async mounted() {
    await this.tryToFetchHistoryByCriteria()
  }
}
</script>
<style>

</style>