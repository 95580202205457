<template>
  <v-container fluid>
    <v-row
    justify="center"
    class="py-10">
      <v-col
      cols="11">
        <h4>
          {{$t('pages.repair.newRepairDescription')}}
        </h4>
      </v-col>
    </v-row>
    <v-snackbar
    top
    right
    v-model="autoSavingModel"
    color="#61c461">
      <div class="d-flex justify-start align-center">
        <v-icon
        size="30"
        color="white"
        class="mr-2">
          mdi-check
        </v-icon>
        <span class="new-repair-group-title">{{$t('pages.repair.dataAutoSavedLocally')}}</span>
      </div>
    </v-snackbar>
    <v-form
    ref="form"
    v-model="isFormValid">
      <v-row 
      dense 
      class="mt-2"
      justify="center" 
      align="center">
        <v-spacer/>
        <v-col cols="2" class="mx-2">
          <report-filter-autocomplete-object
          :label="$t('textFields.itemCategoryLabel')"
          v-model="newPart.category"
          @input="tryToFetchPartsByCriteria"
          item-text="name"
          item-value="key"
          normal
          required
          :items="categories"/>
        </v-col>
        <v-col cols="3" class="mx-2">
          <report-filter-autocomplete-object
          :label="$t('textFields.partLabel')"
          :disabled="!newPart.category"
          v-model="newPart.part"
          item-text="model"
          item-value="id"
          normal
          required
          :items="parts"/>
        </v-col>
        <v-col cols="1" class="mx-2">
          <text-field-number-with-label
          :label="$t('textFields.quantityLabel')"
          v-model="newPart.quantity"
          :disabled="!newPart.part"
          required
          :max="100"
          normal/>
        </v-col>
        <v-col cols="1" class="mx-2">
          <primary-btn
          @click="addPart"
          :label="$t('btns.addPart')"
          color="#4285f4"
          :disabled="!isFormValid"
          :width="100"
          :height="40"/>
        </v-col>
        <v-spacer/>
      </v-row>
    </v-form>
    <v-form
    ref="formExpanded"
    v-model="isExpandedFormValid">
      <v-expansion-panels 
      v-model="expantedPanel"
      class="mt-16"
      multiple>
        <v-expansion-panel
          v-for="(g, gi) in selectedParts"
          :key="gi"
        >
          <v-expansion-panel-header style="background-color: rgba(0,0,0,.03);">
            <span class="new-repair-group-title">
              <span style="font-weight: 400;">
                {{$t('textFields.categoryLabel')}}
              </span>
              {{g.category.name}}; 
              <span style="font-weight: 400;">
                {{$t('textFields.partLabel')}}
              </span>
              {{g.model}}; 
              <span style="font-weight: 400;">
                {{$t('textFields.quantityLabel')}}
              </span>
              {{g.items.length}}
              </span>
              <v-row 
              dense 
              no-gutters>
                <v-col class="d-flex justify-end align-center pr-4">
                  <primary-btn
                  :label="$t('btns.deleteAll')"
                  @click="openConfirmActionDialog(() => deleteRow(null, gi, true),
                  'dialogs.titles.delete',
                  'dialogs.subtitles.deletingItemsOfNewRepairWarning',
                  'btns.deleteAll')"
                  color="#ff3547"
                  :width="100"
                  :height="40"/>
                </v-col>
              </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content
          style="background-color: #eee">
            <main-data-table
            number-pages
            :headers="headers"
            :items="g.items"
            :page.sync="page"
            :size.sync="size"
            :itemsPerPageOptions="itemsPerPageOptions"
            hide-default-footer
            class="pa-4">
              <template v-slot:[`header.image`]="{ header }">
                  {{$t(header.text)}}  
              </template>
              <template v-slot:[`header.category`]="{ header }">
                  {{$t(header.text)}}  
              </template>
              <template v-slot:[`header.model`]="{ header }">
                  {{$t(header.text)}}
              </template>
              <template v-slot:[`header.serialNumber`]="{ header }">
                  {{$t(header.text) + '*'}}
              </template>
              <template v-slot:[`header.serviceTag`]="{ header }">
                  {{$t(header.text)}}
              </template>
              <template v-slot:[`header.problemDescription`]="{ header }">
                  {{$t(header.text)}}
              </template>
              <template v-slot:[`header.deleteRow`]="{ header }">
                  {{$t(header.text)}}
              </template>

              <template v-slot:[`item.image`]="{ item }">
                <previewable-image
                v-model="item.image"
                height="50"
                width="50"
                alt="Part Image"
                contain
                class="pa-5"/>
              </template>
              <template v-slot:[`item.category`]="{ item }">
                  <text-field-value v-model="item.category.name" normal disabled/>
              </template>
              <template v-slot:[`item.model`]="{ item }">
                  <text-field-value v-model="item.model" normal disabled/>  
              </template>
              <template v-slot:[`item.serialNumber`]="{ item }">
                  <text-field-value v-model="item.serialNumber" required normal/>
              </template>
              <template v-slot:[`item.serviceTag`]="{ item }">
                  <text-field-value v-model="item.serviceTag" normal/>
              </template>
              <template v-slot:[`item.problemDescription`]="{ item }">
                  <text-area v-model="item.problemDescription" solo-normal no-resize :height="80"/>
              </template>
              <template v-slot:[`item.deleteRow`]="{ index }">
                  <primary-btn
                  :label="$t('btns.delete')"
                  @click="openConfirmActionDialog(() => deleteRow(index, gi, false),
                  'dialogs.titles.delete',
                  'dialogs.subtitles.deletingItemOfNewRepairWarning',
                  'btns.delete')"
                  color="#ff3547"
                  :width="80"
                  :height="30"/>
              </template>
            </main-data-table>

            <v-row dense class="mt-3">
              <v-col class="d-flex justify-end align-center">
                <primary-btn
                :label="$t('btns.deleteAll')"
                @click="openConfirmActionDialog(() => deleteRow(null, gi, true),
                  'dialogs.titles.delete',
                  'dialogs.subtitles.deletingItemsOfNewRepairWarning',
                  'btns.deleteAll')"
                color="#ff3547"
                :width="130"
                :height="50"/>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>
    <v-row
    class="mt-3"
    justify="end"
    align="center">
      <v-col
        cols="2"
        class="d-flex justify-end align-center mx-2">
          <primary-btn
          @click="openConfirmActionDialog(tryToCreateRepair, 
          'dialogs.titles.validate',
          'dialogs.subtitles.createRepairWarning',
          'btns.submitRequest',
          'btns.continueEditing')"
          :label="$t('btns.submitRequest')"
          color="#ffa000"
          :disabled="selectedParts.length === 0 || !isExpandedFormValid"
          :width="150"
          :height="40"/>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div
              :class="`d-flex justify-center align-center ml-2 ${!isExpandedFormValid ? 'shake-the-help-icon' : ''}`"
              style="width: 20px; height: 20px; border-radius: 50px; border: 2px solid #1976d2; cursor: pointer;"
              v-bind="attrs"
              v-on="on">
                <label style="color: #1976d2;">i</label>
              </div>
            </template>
            <span>{{$t('pages.repair.toolTipRequiredTextFields')}}</span>
          </v-tooltip>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ReportFilterAutocompleteObject from '@/components/report/ReportFilterAutocompleteObject'
import TextFieldNumberWithLabel from '@/components/text-fields/TextFieldNumberWithLabel'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import TextFieldValue from '@/components/text-fields/TextFieldValue.vue'
import TextArea from '@/components/text-fields/TextArea'
import MainDataTable from '@/components/common/MainDataTable'
import PreviewableImage from '@/components/common/PreviewableImage'
import { fetchAllCategories, fetchAllPartsByCategory } from '@/repositories/partRepo'
import { createRepair } from '@/repositories/repairRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { routeFromRoot } from '@/services/routeService'
import { getUserIdentification } from '@/services/authService'
export default {
  components: {
    ReportFilterAutocompleteObject,
    TextFieldNumberWithLabel,
    PrimaryBtn,
    TextFieldValue,
    TextArea,
    MainDataTable,
    PreviewableImage
  },
  data() {
    return {
      categories: [],
      parts: [],
      headers: [{
          text: 'headers.image',
          align: 'start',
          sortable: true,
          value: 'image'
        },{
          text: 'headers.category',
          align: 'start',
          sortable: true,
          value: 'category'
        }, {
          text: 'headers.partName',
          align: 'start',
          sortable: true,
          value: 'model'
        }, {
          text: 'headers.serialNumber',
          align: 'start',
          sortable: true,
          value: 'serialNumber'
        }, {
          text: 'headers.serviceTag',
          align: 'start',
          sortable: true,
          value: 'serviceTag'
        }, {
          text: 'headers.problemDescription',
          align: 'start',
          sortable: true,
          value: 'problemDescription'
        }, {
          text: 'headers.deleteRow',
          align: 'start',
          sortable: true,
          value: 'deleteRow'
        }
      ],
      selectedParts: [],
      newPart: {
        category: null,
        part: null,
        quantity: ''
      },
      isFormValid: false,
      isExpandedFormValid: false,
      autoSavingModel: false,
      autoSavingRepairsIntervalId: null,
      page: 1,
      size: -1,
      itemsPerPageOptions: [-1],
      expantedPanel: []
    }
  },
  methods: {
    async tryToFetchAllCategories() {
      const r = await execHttpRequestThrowable(fetchAllCategories)
      this.categories = [...r.response.data]
    },
    async tryToFetchPartsByCriteria() {
      if (this.newPart.category === null) {
        this.parts = []
        this.newPart.part = null
        this.newPart.quantity = '0'
        return
      }

      const r = await execHttpRequestThrowable(async () => await fetchAllPartsByCategory(this.newPart.category.key, getUserIdentification(), ['REPAIR']))
      this.parts = [...r.response.data]
    },
    async addNewPart(p) {
      this.newPart.category = this.categories.filter(c => c.key === p.category)[0]

      await this.tryToFetchPartsByCriteria()
      this.newPart.part = p
    },
    addPart() {
      let partGroup = null
      let partGroupIndex = -1

      for (let i = 0; i < this.selectedParts.length; i++) {
        if (this.selectedParts[i].category.key === this.newPart.category.key &&
            this.selectedParts[i].model === this.newPart.part.model) {
          partGroupIndex = i
          partGroup = this.selectedParts[i]
          break
        }
      }

      if (!partGroup) {
        const newGroup = {
          category: this.newPart.category,
          model: this.newPart.part.model,
          items: []
        }
        this.selectedParts.push(newGroup)
        partGroup = newGroup
        partGroupIndex = this.selectedParts.length - 1
      }

      if (!this.expantedPanel.includes(partGroupIndex)) {
        this.expantedPanel.push(partGroupIndex)
      }

      for(let c = 0; c < parseInt(this.newPart.quantity); c++) {
        partGroup.items.push({
          image: this.newPart.part.imageUrl,
          category: this.newPart.category,
          model: this.newPart.part.model,
          serialNumber: '',
          serviceTag: '',
          problemDescription: '',
          partId: this.newPart.part.id
        })
      }

      this.newPart.category = null
      this.newPart.part = null
      this.newPart.quantity = ''
      this.$refs.form.reset()
    },
    deleteRow(i, gi, all) {
      const group = this.selectedParts[gi]

      if (!all) group.items.splice(i, 1)
      if (group.items.length === 0 || all)  this.selectedParts.splice(gi, 1)

      this.autoSaveRepairs(false)
    },
    autoSaveRepairs(notifyUser) {
      localStorage.setItem('local-Repairs', JSON.stringify(this.selectedParts))
      if (notifyUser) this.autoSavingModel = true
    },
    openConfirmActionDialog(a, t, st, abl, cbl) {
      this.$confirmActionDialog.open(t, st, abl, cbl, a)
    },
    async tryToCreateRepair() { 
      let parts = []
      for (const g of this.selectedParts) parts = [...parts, ...g.items]
      await execHttpRequestThrowable(async () => await createRepair(parts))
      localStorage.removeItem('local-Repairs')
      routeFromRoot('active-repairs')
    }
  },
  async mounted() {
    const localRepairs = localStorage.getItem('local-Repairs')
    if (localRepairs && localRepairs !== 'null') {
      this.selectedParts = JSON.parse(localRepairs)
      let found = false
      for (const g of this.selectedParts) {
        for (const p of g.items) {
          if (!p.serialNumber || p.serialNumber.length === 0) {
            found = true
            break
          }
        }
        if (found) {
          this.isExpandedFormValid = false
          break
        }
      }
    }
    this.autoSavingRepairsIntervalId = setInterval(() => {this.autoSaveRepairs(true)}, 30000)

    await this.tryToFetchAllCategories()

    this.$feedback.fill("New Repair Page", this.$route.path)
  },
  beforeDestroy() {
    if (this.autoSavingRepairsIntervalId) clearInterval(this.autoSavingRepairsIntervalId)
  }
}
</script>
<style>

.detail-btn.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light {
  color: #fff;
  background-color: #4285f4!important;
  border-color: #75a4f0;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  font-size: 0.65rem;
  letter-spacing: 1px;
}
.new-repair-group-title {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  letter-spacing: 0px;
}
.v-expansion-panel-header__icon > i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light {
  color: blue;
}

.shake-the-help-icon {
  animation-name: shaking-help-icon-anim;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-iteration-count: 5;
}

@keyframes shaking-help-icon-anim {
  0% {
    position: relative;
    left: 0px;
    transform: rotateZ(0deg)
  }
  50% {
    position: relative;
    left: 10px;
    transform: rotateZ(20deg)
  }
  100% {
    position: relative;
    left: 0px;
    transform: rotateZ(0deg)
  }
}
</style>