import { get, post, put } from '@/services/http'
import { getUserIdentification, getUserToken } from '@/services/authService'

export function createContact(data) {
  return post(`/api/v1/contacts`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}

export function editContact(data) {
  return post(`/api/v1/contacts/edit`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}

export function fetchContactByUsername(username) {
  return get(`/api/v1/contacts/fetch-by-username?username=${username}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}

export function fetchContactsByCriteria(keyword, size, page, sort) {
  return get(`/api/v1/contacts/fetch/criteria?size=${size}&page=${page}&sort=${sort}${keyword !== undefined && keyword !== null ? `&keyword=${keyword}` : ""}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}

export function fetchProfile(username) {
  return get(`/api/v1/contacts/profile?username=${username}`, {
    headers: {
      'Authorization': getUserToken()
    }
  })
}

export function requestAccount(data) {
  return put(`/api/v1/contacts/request-account/request`, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function setRequestAccountDesicion(id, s) {
  return post(`/api/v1/contacts/request-account/decision?username=${getUserIdentification()}&id=${id}&status=${s}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getUserToken()
    }
  })
}
