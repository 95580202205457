import Vue from 'vue'
import { fetchNumberOfActionRequiredRepair } from '@/repositories/repairRepo'
import { execHttpRequestThrowable } from '@/services/http'

class ActionRequiredPlugin {
  allNumber
  partsNumber
  remoteSupport
  constructor() {
    this.allNumber = 0
    this.partsNumber = 0
    this.remoteSupport = 0
  }
  setAll(n) {
    this.allNumber = n
  }
  getAll() {
    return this.allNumber
  }
  setParts(n) {
    this.partsNumber = n
  }
  getParts() {
    return this.partsNumber
  }
  setRemoteSupport(n) {
    this.remoteSupport = n
  }
  getRemoteSupport() {
    return this.remoteSupport
  }
  async fetch() {
    const r = await execHttpRequestThrowable(async () => await fetchNumberOfActionRequiredRepair())
    let total = 0
    r.response.data.forEach(o => total += o.total)
    this.setAll(total)
    this.setParts(r.response.data.filter(o => o.requestType === "PARTS")[0].total)
    this.setRemoteSupport(r.response.data.filter(o => o.requestType === "REMOTE_SUPPORT")[0].total)
    // this.setRepair(r.response.data.filter(o => o.requestType === "REPAIR")[0].total)
  }
  startFetching() {
    return setInterval(async () => await this.fetch(), 10000)
  }
  clearAll() {
    this.allNumber = 0
    this.partsNumber = 0
    this.remoteSupport = 0
  }
}

export default {
  //(app, options)
  install: (app) => {
    app.prototype.$actionRequired = Vue.observable(new ActionRequiredPlugin())
  }
}