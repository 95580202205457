const MAIL_ACTION_TYPE = [
  {
    key: 'CHANGE_REMOTE_SUPPORT_REQUEST_SERVICE',
    value: 'mailActionTypes.changeRemoteSupportRequestService'
  }
]
export function getMailActionTypeByKey(k) {
  const s = MAIL_ACTION_TYPE.filter(s => s.key === k)

  if (s.length > 0) return s[0].value
  else return null
}
export function getObjectTypeByKey(k) {
  const s = MAIL_ACTION_TYPE.filter(s => s.key === k)

  if (s.length > 0) return {
    key: String(s[0].key),
    value: String(s[0].value)
  }
  else return null
}