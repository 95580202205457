<template>
  <v-container
  v-if="isDataReady"
  fluid>
    <v-row>
      <v-col class="px-8">
        <span class="mail-notification-title">
          {{$t('pages.mail.settingRoleSubtitle')}}
        </span>
      </v-col>
    </v-row>
    <v-row
    class="px-4">
      <v-col>
        <common-tabs
        v-model="serviceCategoryTab"
        :items="getTabs"/>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col>
        <main-data-table
        number-pages
        :headers="headers"
        :items="newData[serviceCategoryTab].settings"
        :page.sync="page"
        :size.sync="size"
        :loading="isLoading"
        :itemsPerPageOptions="itemsPerPageOptions"
        item-key="number"
        hide-default-footer
        class="pa-4">
          <template v-slot:[`item.processStatus`]="{ item }">
              <span>
                {{$t(getStatus(item.processStatus, newData[serviceCategoryTab].key, item))}}
              </span>
          </template>
          <template v-slot:[`item.isClient`]="{ item }">
            <text-field-switch
            v-model="item.isClient"
            color="purple"
            style="position: relative; top: -8px;"/>
          </template>
          <template v-slot:[`item.isService`]="{ item }">
            <text-field-switch
            v-model="item.isService"
            color="purple"
            style="position: relative; top: -8px;"/>
          </template>
          <template v-slot:[`item.isSupervisor`]="{ item }">
            <text-field-switch
            v-model="item.isSupervisor"
            color="purple"
            style="position: relative; top: -8px;"/>
          </template>
          <template v-slot:[`item.isAdministrator`]="{ item }">
            <text-field-switch
            v-model="item.isAdministrator"
            color="purple"
            style="position: relative; top: -8px;"/>
          </template>
          <template v-slot:[`item.isSustainabilityManager`]="{ item }">
            <text-field-switch
            v-model="item.isSustainabilityManager"
            color="purple"
            style="position: relative; top: -8px;"/>
          </template>
        </main-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center align-center">
        <primary-btn 
        :label="$t('btns.save')"
        @click="tryToSaveData"
        :loading="isLoading"
        :width="90" 
        :height="45"
        color="#4285f4"/>
      </v-col>
    </v-row>
  </v-container>
  <div v-else></div>
</template>
<script>
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import MainDataTable from '@/components/common/MainDataTable'
import TextFieldSwitch from '@/components/text-fields/TextFieldSwitch'
import CommonTabs from '@/components/common/tabs/CommonTabs'
import { getRepairStatusByKey, getPartRequestStatusByKey, getRemoteSupportStatusByKey } from '@/utils/statusesUtil'
import { fetchAllNotificationSettings, editNotificationSettings } from '@/repositories/mailRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { getMailActionTypeByKey } from "@/utils/actionTypeUtil"
export default {
  components: {
    PrimaryBtn,
    TextFieldSwitch,
    MainDataTable,
    CommonTabs
  },
  data() {
    return {
      headers: [
        {
          text: 'headers.status',
          align: 'start',
          sortable: true,
          value: 'processStatus'
        },{
          text: 'headers.client',
          align: 'start',
          sortable: true,
          value: 'isClient'
        },{
          text: 'headers.service',
          align: 'start',
          sortable: true,
          value: 'isService'
        },{
          text: 'headers.supervisor',
          align: 'start',
          sortable: true,
          value: 'isSupervisor'
        }, {
          text: 'headers.admin',
          align: 'start',
          sortable: true,
          value: 'isAdministrator'
        }, {
          text: 'headers.sustainabilityManager',
          align: 'start',
          sortable: true,
          value: 'isSustainabilityManager'
        }
      ],
      isLoading: false,
      oldData: [],
      newData: [],
      page: 1,
      size: -1,
      itemsPerPageOptions: [-1],
      serviceCategoryTab: 0,
      isDataReady: false
    }
  },
  computed: {
    getTabs() {
      return this.newData.map(i => {
        return {
          label: `common.serviceCategories.${i.key}`,
          settings: i.settings
        }
      })
    }
  },
  methods: {
    async tryToFetchNotificationSettings() {
      const r = await execHttpRequestThrowable(fetchAllNotificationSettings)
      this.oldData = [...r.response.data]
      this.newData = [...r.response.data]
    },
    async tryToSaveData() {
      const messages = {
        _200: 'dialogs.messages.success.mailNotificationSettingsSavedSuccessfully',
        _400: this.$t('dialogs.messages.failed.mailNotificationSettingsSavingError')
      }

      const { id, settings } = this.newData[this.serviceCategoryTab]

      settings.forEach(s => {
        if (s.isClient === null) s.isClient = false
        if (s.isService === null) s.isService = false
        if (s.isSupervisor === null) s.isSupervisor = false
        if (s.isAdministrator === null) s.isAdministrator = false
        if (s.isSustainabilityManager === null) s.isSustainabilityManager = false
      })
      const body = {
        type: id != -1 ? "PROCESS" : "SINGLE",
        settings
      }
      await execHttpRequestThrowable(async () => await editNotificationSettings(body), messages, v => this.isLoading = v)
      this.oldData[this.serviceCategoryTab].settings = [...settings]
    },
    getStatus(key, category, item) {
      if ("processStatus" in item) {
        switch(category) {
          case 'REPAIR': return getRepairStatusByKey(key)
          case 'PARTS': return getPartRequestStatusByKey(key)
          case 'REMOTE_SUPPORT': return getRemoteSupportStatusByKey(key)
          default: return null
        }
      }
      else if ("actionType" in item) {
        return getMailActionTypeByKey(item.actionType)
      }
    }
  },
  async mounted() {
    this.isDataReady = false
    await this.tryToFetchNotificationSettings()
    this.isDataReady = true
  },
  created() {
    this.$feedback.fill("Mail Notification Page", this.$route.path)
  }
}
</script>
<style>
.mail-notification-title {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 1px;
}
</style>