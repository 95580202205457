<template>
  <v-btn
  @click="btn.link ? routeTo() : btn.action()"
  :class="`nav-btn text-none ${btn.highlight ?  'nav-item-pushed' : ''}`"
  text
  dark>
    <v-icon 
    size="19"
    class="mr-1">
      {{btn.icon}}
    </v-icon>
    {{$t(btn.label)}}
    <notification-badge
    v-if="getNotificationNumber && getNotificationNumber > 0"
    :value="getNotificationNumber"/>
  </v-btn>
</template>
<script>
import { routeFromRoot } from '@/services/routeService'
import NotificationBadge from '@/components/common/NotificationBadge'
export default {
  components: {
    NotificationBadge
  },
  props: {
    btn: Object
  },
  data() {
    return { }
  },
  computed: {
    getNotificationNumber() {
      return this.btn.notificationCount !== null && this.btn.notificationCount !== undefined ? this.btn.notificationCount() : null
    }
  },
  methods: {
    routeTo() {
      routeFromRoot(this.btn.link)
    }
  },
  mounted() {
  },
  beforeDestroy() {
  }
}
</script>
<style>
.nav-btn.v-btn.v-btn--text.theme--dark {
  font-family: 'Titillium Web', sans-serif;
  font-size: 1.05rem;
  line-height: inherit;
  letter-spacing: 0px;
  font-weight: 300;
  padding: 0px 7px;
}

.v-btn.v-btn--text.theme--dark.v-size--default.nav-btn.text-none.nav-item-pushed {
  background: #8cb9ff;
}
</style>