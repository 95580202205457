<template>
  <v-card
  v-if="isDataReady"
  width="460"
  min-height="765"
  class="rounded-0 primary-background-color"
  elevation="5">
    <v-row
    class="px-8"
    dense
    no-gutters>
      <v-col class="pt-8">
        <p class="card-title">
          {{$t('pages.contact.clientServiceDetailsTitle', {'0': (isCurrentRole(['ADMIN']) && isSelectedtRole(['CLIENT'])) || (isCurrentRole(['SUSTAINABILITY_MANAGER']) && isSelectedtRole(['SUSTAINABILITY_MANAGER'])) ? $t('roles.client') : $t('roles.service')})}}
        </p>
      </v-col>
    </v-row>
    <v-form 
    v-if="isCurrentRole(['ADMIN']) && isSelectedtRole(['CLIENT'])"
    ref="form"
    v-model="isFormValidated">
      <v-row dense no-gutters class="px-8 mt-2">
        <v-col>
          <text-field-value 
          :label="$t('textFields.clientName')"
          v-model="newData.clientForAdmin.name"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8">
        <v-col>
          <text-field-country-code 
          v-model="newData.clientForAdmin.countryCode"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8">
        <v-col>
          <text-field-autocomplete 
          v-model="newData.clientForAdmin.service"
          :label="$t('roles.service')"
          item-text="name"
          item-value="id"
          :items="services"
          required/>
        </v-col>
      </v-row>
    </v-form>
    <v-form 
    v-else-if="isCurrentRole(['ADMIN']) && isSelectedtRole(['SERVICE'])"
    ref="form"
    v-model="isFormValidated">
      <v-row dense no-gutters class="px-8 mt-2">
        <v-col>
          <text-field-switch
          :label="$t('textFields.doesServiceSupportSpecialServices')"
          v-model="newData.serviceForAdmin.supportSpecialServices"
          />
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8 mt-2">
        <v-col>
          <text-field-value 
          :label="$t('textFields.serviceName')"
          v-model="newData.serviceForAdmin.name"
          required/>
        </v-col>
      </v-row>
    </v-form>
    <v-form 
    v-else-if="isCurrentRole(['CLIENT'])"
    ref="form"
    v-model="isFormValidated">
      <v-row dense no-gutters class="px-8 mt-2">
        <v-col>
          <text-field-value 
          :label="$t('textFields.username')"
          readonly
          v-model="newData.serviceForClient.username"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8">
        <v-col>
          <text-field-value 
          :label="$t('textFields.contactPerson')"
          readonly
          v-model="newData.serviceForClient.contactFullName"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8">
        <v-col>
          <text-field-value 
          readonly
          :label="$t('textFields.contactEmail')"
          v-model="newData.serviceForClient.contactEmail"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8">
        <v-col>
          <text-field-value 
          readonly
          :label="$t('textFields.country')"
          v-model="newData.serviceForClient.country"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8">
        <v-col>
          <text-field-value 
          readonly
          :label="$t('textFields.city')"
          v-model="newData.serviceForClient.city"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8">
        <v-col>
          <text-field-value 
          readonly
          :label="$t('textFields.postalCode')"
          v-model="newData.serviceForClient.postalCode"
          required/>
        </v-col>
      </v-row>
      <v-row dense no-gutters class="px-8">
        <v-col>
          <text-field-value 
          readonly
          :label="$t('textFields.address')"
          v-model="newData.serviceForClient.address"
          required/>
        </v-col>
      </v-row>
    </v-form>
    <!-- <v-form 
    v-else-if="isCurrentRole(['SERVICE'])"
    ref="form"
    v-model="isFormValidated">
      <v-row dense no-gutters class="px-8 mt-2">
        <v-col>
          <text-field-number 
          :label="$t('textFields.pricePerHour')"
          readonly
          double
          v-model="newData.serviceForService.price"
          required/>
        </v-col>
      </v-row>
    </v-form> -->
    <v-form 
    v-else-if="isCurrentRole(['SUSTAINABILITY_MANAGER']) || isSelectedtRole(['SUSTAINABILITY_MANAGER'])"
    ref="form"
    v-model="isFormValidated">
      <v-row dense no-gutters class="px-8 mt-2">
        <v-col>
          <text-field-autocomplete-chips
          v-model="newData.managerForAdmin.clients"
          :label="$t('textFields.clients')"
          :items="allClients"
          item-value="id"
          item-text="name"
          :disabled="isCurrentRole(['SUSTAINABILITY_MANAGER'])"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-row v-if="!isCurrentRole(['CLIENT', 'SUSTAINABILITY_MANAGER'])" dense no-gutters class="px-8 pt-10">
      <v-col class="d-flex justify-center align-center">
        <primary-btn
        v-if="!readonly"
        :label="$t('btns.save')"
        color="#4285f4"
        :disabled="!isFormValidated"
        @click="setChangedDialogItems"
        :loading="isLoading"
        :width="100"
        :height="40"/>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import TextFieldValue from '@/components/text-fields/TextFieldValue'
// import TextFieldNumber from '@/components/text-fields/TextFieldNumber'
import TextFieldCountryCode from '@/components/text-fields/TextFieldCountryCode'
import PrimaryBtn from '@/components/btns/PrimaryBtn'
import TextFieldAutocomplete from '@/components/text-fields/TextFieldAutocomplete'
import TextFieldAutocompleteChips from '@/components/text-fields/TextFieldAutocompleteChips'
import TextFieldSwitch from '@/components/text-fields/TextFieldSwitch'
import { fetchAllServices } from '@/repositories/serviceRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { editClient, fetchAllClients } from '@/repositories/clientRepo'
import { editService } from '@/repositories/serviceRepo'
import { saveClientsForManager } from '@/repositories/managerRepo'
import { getUserIdentification } from '@/services/authService'
import { cloneObj, compareObjects, copyObj } from '@/utils/StringUtil'
export default {
  components: {
    TextFieldValue,
    // TextFieldNumber,
    TextFieldAutocomplete,
    TextFieldCountryCode,
    TextFieldAutocompleteChips,
    TextFieldSwitch,
    PrimaryBtn
  },
  props: {
    additionalData: Object,
    readonly: Boolean,
    currentUserRole: String,
    selectedUserRole: String
  },
  data() {
    return {
      isFormValidated: false,
      allClients: [],
      newData: {
        clientForAdmin: null,
        serviceForAdmin: null,
        serviceForClient: null,
        serviceForService: null,
        managerForAdmin: null
      },
      services: [],
      user: {
        firstName: '',
        address: {
          postalCode: ''
        }
      },
      isLoading: false,
      isDataReady: false
    }
  },
  computed: {
    additionalDataProp: {
      get: function() {
        return this.$props.additionalData
      },
      set: function (v) {
        this.$emit('update:additionalData', v)
      }
    }
  },
  methods: {
    async tryToSaveData() {
      try {
        this.isLoading = true

        if (this.additionalDataProp.clientForAdmin) await this.tryToSaveClientData()
        else if (this.additionalDataProp.serviceForAdmin || this.additionalDataProp.serviceForService) await this.tryToSaveServiceData()
        else if (this.additionalDataProp.managerForAdmin) await this.tryToSaveManagerData()
      }
      finally {
        this.isLoading = false
      }
    },
    async tryToSaveClientData() {
      const data = {
        adminUsername: getUserIdentification(),
        oldClientName: this.additionalDataProp.clientForAdmin.name,
        newClientName: this.newData.clientForAdmin.name,
        countryCode: this.newData.clientForAdmin.countryCode,
        service: this.newData.clientForAdmin.service
      }
      const messages = {
        _200: 'dialogs.messages.success.clientDataSavedSuccessfully',
        _409: 'dialogs.messages.failed.clientWithNameAlreadyExists'
      }
      await execHttpRequestThrowable(async () => await editClient(data), messages, v => this.isLoading = v)
      cloneObj(this.newData.clientForAdmin, this.additionalDataProp.clientForAdmin)
    },
    async tryToSaveServiceData() {
      let serviceContactUsername

      if (this.additionalDataProp.serviceForAdmin) serviceContactUsername = this.additionalDataProp.serviceForAdmin.serviceContactUsername
      else if (this.additionalDataProp.serviceForService) serviceContactUsername = this.additionalDataProp.serviceForService.serviceContactUsername

      const data = {
        adminUsername: getUserIdentification(),
        serviceContactUsername: serviceContactUsername,
        oldServiceName: this.additionalDataProp.serviceForAdmin ? this.additionalDataProp.serviceForAdmin.name : null,
        newServiceName: this.newData.serviceForAdmin ? this.newData.serviceForAdmin.name : null,
        price: this.newData.serviceForService ? this.newData.serviceForService.price : null,
        supportSpecialServices: this.newData.serviceForAdmin ? this.newData.serviceForAdmin.supportSpecialServices : null
      }
      const messages = {
        _200: 'dialogs.messages.success.serviceDataSavedSuccessfully',
        _409: 'dialogs.messages.failed.serviceWithNameAlreadyExists'
      }
      await execHttpRequestThrowable(async () => await editService(data), messages, v => this.isLoading = v)

      if (this.additionalDataProp.serviceForAdmin) {
        this.additionalDataProp.serviceForAdmin.name = String(this.newData.serviceForAdmin.name)
        this.additionalDataProp.serviceForAdmin.supportSpecialServices = this.newData.serviceForAdmin.supportSpecialServices
      }
      else if (this.additionalDataProp.serviceForService) {
        this.additionalDataProp.serviceForService.price = this.newData.serviceForService.price
      }
    },
    async tryToSaveManagerData() {
      const messages = {
        _200: 'dialogs.messages.success.serviceDataSavedSuccessfully'
      }
      await execHttpRequestThrowable(async () => await saveClientsForManager(this.$route.params.username, this.newData.managerForAdmin.clients), messages,
      v => this.isLoading = v)
      cloneObj(this.newData.managerForAdmin, this.additionalDataProp.managerForAdmin)
    },
    getDataForChangesDialogProcess() {
      if (this.newData.clientForAdmin) {
        return {
          old: this.additionalDataProp.clientForAdmin,
          _new: this.newData.clientForAdmin,
          props: [
            {
              field: 'textFields.clientName',
              prop: 'name'
            }, {
              field: 'textFields.countryCode',
              prop: 'countryCode'
            }, {
              field: 'roles.service',
              prop: 'service.name'
            }
          ]
        }
      }
      else if (this.newData.serviceForAdmin) {
        return {
          old: this.additionalDataProp.serviceForAdmin,
          _new: this.newData.serviceForAdmin,
          props: [
            {
              field: 'textFields.serviceName',
              prop: 'name'
            }, {
              field: 'textFields.doesServiceSupportSpecialServices',
              prop: 'supportSpecialServices'
            }
          ]
        }
      }
      //                                                                The card is hidden for service role for now!
      // else if (this.newData.serviceForService) {
      //   return {
      //     old: this.additionalDataProp.serviceForService,
      //     _new: this.newData.serviceForService,
      //     props: [
      //       {
      //         field: 'textFields.price',
      //         prop: 'price'
      //       }
      //     ]
      //   }
      // }
      else if (this.newData.managerForAdmin) {
        return {
          old: this.additionalDataProp.managerForAdmin,
          _new: this.newData.managerForAdmin,
          props: [
            {
              field: {
                value: 'textFields.clientNo',
                params: null
              },
              prop: 'clients[].name'
            },
          ]
        }
      }
    },
    isCurrentRole(roles) {
      return roles.includes(this.currentUserRole)
    },
    isSelectedtRole(roles) {
      return roles.includes(this.selectedUserRole)
    },
    setChangedDialogItems() {
      const {old, _new, props} = this.getDataForChangesDialogProcess()
      this.$changesDialog.open(
        'dialogs.titles.changes',
        compareObjects(old, _new, props),
        this.tryToSaveData,
        false
      )
    },
    async tryToFetchAllServices() {
      const r = await execHttpRequestThrowable(fetchAllServices, {}, v => this.isLoading = v)
      this.services = [...r.response.data]
    },
    async tryToFetchAllClients() {
      const r = await execHttpRequestThrowable(fetchAllClients, {}, v => this.isLoading = v)
      this.allClients = [...r.response.data]
    }
  },
  async mounted() {
    if (this.additionalDataProp.clientForAdmin) {
      await this.tryToFetchAllServices()
      this.newData = {
        clientForAdmin: copyObj(this.additionalDataProp.clientForAdmin)
      }
    }
    else if (this.additionalDataProp.serviceForAdmin) {
      this.newData = {
        serviceForAdmin: copyObj(this.additionalDataProp.serviceForAdmin)
      }
    }
    else if (this.additionalDataProp.serviceForClient) {
      this.newData = {
        serviceForClient: copyObj(this.additionalDataProp.serviceForClient)
      }
    }
    else if (this.additionalDataProp.serviceForService) {
      this.newData = {
        serviceForService: copyObj(this.additionalDataProp.serviceForService)
      }
    }
    else if (this.additionalDataProp.managerForAdmin) {
      await this.tryToFetchAllClients()
      this.newData = {
        managerForAdmin: copyObj(this.additionalDataProp.managerForAdmin)
      }
    }

    this.isDataReady = true
  }
}
</script>
<style>

</style>