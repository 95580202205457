<template>
  <div style="position: relative; top: -12px;">
    <label class="text-input-label">
      {{$t('textFields.searchLabel')}}
    </label>
    <v-text-field
    class="text-input text-input-border-color select-medium"
    hide-details="auto"
    v-model="model"
    @keypress.enter="$emit('enter')"
    solo
    flat
    dense
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  model: {
    prop: "value",
    event: "valueChanged"
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit("valueChanged", v)
      }
    }
  }
}
</script>

<style>

</style>