<template>
  <v-container
  v-if="isDataReady"
  fluid>
      <v-row
      dense>
        <v-col>
          <p class="process-repair-tab-section-title">{{$t(`pages.remoteSupport.${$parent.isRepairStatus(['WAITING_COMPLETION']) ? 'repairWaitingCompletion' : 'repairCompleted'}`)}}</p>
        </v-col>
      </v-row>
      <v-form
      v-if="$parent.isRole(['ADMIN', 'SERVICE'])"
      v-model="isFormValid">
        <v-row
        justify="center">
          <v-col
          cols="6">
            <text-area 
            v-model="newData.summary" 
            :label="$t('textFields.summary')" 
            :disabled="disabled"
            required/>
          </v-col>
        </v-row>
      </v-form>
      <v-row 
      dense 
      class="my-10">
        <v-col class="d-flex justify-center align-center">
          <process-repair-quick-btns
          ref="quickBtnsRef"
          :config="getQuickBtnsConfig"
          :loading.sync="isLoading"
          on-success-repair-status-message="dialogs.messages.success.completeRemoteSupportRequest"/>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ProcessRepairQuickBtns from '@/components/process-repair-tabs/ProcessRepairQuickBtns'
import TextArea from '@/components/text-fields/TextArea'
import { updateServiceReviewSummary } from '@/repositories/supportRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { compareObjects } from '@/utils/StringUtil'
export default {
  name: 'WaitingCompletionTabRemoteSupport',
  components: {
    ProcessRepairQuickBtns,
    TextArea
  },
  data() {
    return {
      isFormValid: false,
      isLoading: false,
      newData: null,
      isDataReady: false
    }
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    disabled: Boolean
  },
  methods: {
    openConfirmDialog() {
      this.$confirmActionDialog.open(
      'dialogs.titles.completeRemoteSupportRequest', 
      "dialogs.subtitles.aboutToCompleteRemoteSupportRequest", 
      'btns.complete',
      null,
      async () => await this.$refs.quickBtnsRef.tryToUpdateEntityStatus())
    },
    setChangedDialogItems() {
      const old = this.remoteSupportProp.serviceReview
      const _new = this.newData
      const props = [
        {
          field: 'textFields.summary',
          prop: 'summary'
        }
      ]

      this.$changesDialog.open(
        'dialogs.titles.changes',
        compareObjects(old, _new, props),
        this.tryToSaveData,
        false
      )
    },
    async tryToSaveData() {
      const messages = {
        _200: 'dialogs.messages.success.serviceReviewSummarySavedSuccessfully'
      }

      await execHttpRequestThrowable(
        async () => await updateServiceReviewSummary(this.$route.params.id, this.newData.summary),
        messages,
        v => this.isLoading = v)
      this.remoteSupportProp.serviceReview.summary = String(this.newData.summary)
      this.newData.isAllowedToContinue = true
    },
  },
  computed: {
    remoteSupportProp: {
      get: function() {
        return this.$props.data
      },
      set: function(v) {
        this.$emit('update:data', v)
      }
    },
    getQuickBtnsConfig() {
      const btns = []

      if (this.$parent.isRole(['ADMIN', 'SERVICE'])) btns.push({
          name: 'Save',
          events: {
            'click': this.setChangedDialogItems
          },
          props: {
            'disabled': this.disabled || !this.isFormValid
          }
        })

      btns.push({
        name: 'Complete',
        props: {
          'disabled': this.disabled || this.$parent.isNotRole(['ADMIN', 'SERVICE']) || !this.newData.isAllowedToContinue
        },
        events: {
          click: () => this.openConfirmDialog()
        }
      })
      return btns
    }
  },
  mounted() {
    this.newData = {
      isAllowedToContinue: this.remoteSupportProp.allowedToContinue,
      summary: String(this.remoteSupportProp.serviceReview.summary)
    }
    this.isDataReady = true
  }
}
</script>
<style>

</style>