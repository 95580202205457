<template>
  <v-row>
    <v-col>
      <text-field-check-box
      v-model="phoneProp"
      :label="$t(`pages.remoteSupport.preferredContactMethod.PHONE`)"
      :readonly="readonly"
      :error="checkboxError"
      dense/>
    </v-col>
    <v-col>
      <text-field-check-box
      v-model="viberProp"
      :label="$t(`pages.remoteSupport.preferredContactMethod.VIBER`)"
      :readonly="readonly"
      :error="checkboxError"
      dense/>
    </v-col>
    <v-col>
      <text-field-check-box
      v-model="whatsAppProp"
      :label="$t(`pages.remoteSupport.preferredContactMethod.WHATS_APP`)"
      :readonly="readonly"
      :error="checkboxError"
      dense/>
    </v-col>
    <v-col>
      <text-field-check-box
      v-model="skypeProp"
      :label="$t(`pages.remoteSupport.preferredContactMethod.SKYPE`)"
      :readonly="readonly"
      :error="checkboxError"
      dense/>
    </v-col>
  </v-row>
</template>

<script>
import TextFieldCheckBox from '@/components/text-fields/TextFieldCheckBox'
export default {
  components: {
    TextFieldCheckBox
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "value",
    event: "valueChanged"
  },
  data() {
    return {
      methods: {
        phone: {
          model: null,
          key: "PHONE"
        }, 
        viber: {
          model: null,
          key: "VIBER"
        }, 
        whatsApp: {
          model: null,
          key: "WHATS_APP"
        },
        skype: {
          model: null,
          key: "SKYPE"
        }
      },
      checkboxError: false
    }
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit("valueChanged", v)
      }
    },
    phoneProp: {
      get: function() {
        return this.$data.methods.phone.model
      },
      set: function(v) {
        this.$data.methods.phone.model = v
        this.updateModel(this.$data.methods.phone, v)
      }
    },
    viberProp: {
      get: function() {
        return this.$data.methods.viber.model
      },
      set: function(v) {
        this.$data.methods.viber.model = v
        this.updateModel(this.$data.methods.viber, v)
      }
    },
    whatsAppProp: {
      get: function() {
        return this.$data.methods.whatsApp.model
      },
      set: function(v) {
        this.$data.methods.whatsApp.model = v
        this.updateModel(this.$data.methods.whatsApp, v)
      }
    },
    skypeProp: {
      get: function() {
        return this.$data.methods.skype.model
      },
      set: function(v) {
        this.$data.methods.skype.model = v
        this.updateModel(this.$data.methods.skype, v)
      }
    }
  },
  methods: {
    updateModel({key}, state) {
      if (state) {
        const newArray = [...this.model]
        newArray.push(key)
        this.model = newArray
      }
      else this.model = this.model.filter(o => o !== key)

      this.validate()
    },
    fillDataOnMount() {
      for (const v of Object.values(this.methods)) {
        const el = this.model.filter(o => o === v.key)[0]
        v.model = !!el
      }
    },
    validate() {
      if (!this.required) return true

      const isValid = Object.values(this.methods).filter(o => o.model === true).length > 0

      this.checkboxError = !isValid

      return isValid
    }
  },
  mounted() {
    this.fillDataOnMount()
  }
}
</script>

<style>

</style>