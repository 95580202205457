<template>
  <v-app-bar
    app
    dark
    height="55"
    class="nav-bar-primary-color"
  >
    <div 
    class="d-flex align-center">
      <label 
      style="cursor: pointer;"  
      class="nav-bar-title"
      @click="routeTo('active-repairs')">{{$t('pages.contact.helloUser', {'0': getUsername})}}</label>
    </div>
    <div
    v-for="(g, gi) in navBtnGroups" 
    :key="gi"
    :style="`width: ${g.width}%;`"
    class="mx-1">
      <div
      :class="`d-flex justify-${g.justify} align-center`">
        <div
        v-if="g.setSideLines"
        class="nav-bar-group-btn-side-line mr-1">

        </div>
        <div
        v-for="(b, bi) in g.btns.filter(x => isGranted(x.roles))" 
        :key="bi"
        class="mx-1 d-flex justify-center align-center">
          <nav-btn
          v-if="!b.children"
          :btn="b"/>
          <nav-menu
          v-else-if="b.children"
          :btn="b"
          />
          <div
          v-if="g.setSideLines && bi < g.btns.filter(x => isGranted(x.roles)).length - 1"
          class="nav-bar-group-btn-side-line ml-2">

          </div>
        </div>
        <div
        v-if="g.setSideLines"
        class="nav-bar-group-btn-side-line ml-1">

        </div>
      </div>
    </div>
  </v-app-bar>
</template>
<script>
import NavBtn from '@/components/navigation/NavBtn'
import NavMenu from '@/components/navigation/NavMenu'
import { DynamicNotifier } from '@/utils/DynamicNotifier.js'
import { routeFromRoot } from '@/services/routeService'
import { logout } from '@/repositories/authRepo'
import { execHttpRequestThrowable } from '@/services/http'
import { removeUserToken, getUserRole, getUserIdentification } from '@/services/authService'
import { fetchContactByUsername } from '@/repositories/contactRepo'
export default {
  components: {
    NavBtn,
    NavMenu
  },
  data() {
    return {
      navBtnGroups: [{
          roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
          btns: [
            ////
            //For SUSTAINABILITY_MANAGER these two are BUTTONS, not MENUS!
            ////
            {
              label: 'pages.partRequest.menu',
              icon: 'mdi-chat-question',
              link: 'part-request/list',
              roles: ['SUSTAINABILITY_MANAGER'],
              children: null,
              highlight: false
            }, 
            {
              label: 'pages.remoteSupport.menu',
              icon: 'mdi-face-agent',
              link: 'remote-support/list',
              roles: ['SUSTAINABILITY_MANAGER'],
              children: null,
              highlight: false
            }, 
            /////////////////////////////////////
            {
              label: 'pages.partRequest.menu',
              icon: 'mdi-chat-question',
              roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT'],
              children: [
                {
                  label: 'pages.partRequest.newPartRequest',
                  icon: null,
                  link: 'part-request/new',
                  roles: ['CLIENT'],
                }, {
                  label: 'pages.partRequest.partRequestList',
                  icon: null,
                  link: 'part-request/list',
                  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT'],
                }
              ],
              highlight: false,
              notificationCount: () => this.$actionRequired.partsNumber
            }, {
              label: 'pages.remoteSupport.menu',
              icon: 'mdi-face-agent',
              roles: ['ADMIN', 'SUPERVISOR', 'CLIENT', 'SERVICE'],
              children: [
                {
                  label: 'pages.remoteSupport.newRemoteSupport',
                  icon: null,
                  link: 'remote-support/new',
                  roles: ['CLIENT'],
                }, {
                  label: 'pages.remoteSupport.remoteSupportList',
                  icon: null,
                  link: 'remote-support/list',
                  roles: ['ADMIN', 'SUPERVISOR', 'CLIENT', 'SERVICE'],
                }
              ],
              highlight: false,
              notificationCount: () => this.$actionRequired.remoteSupport
            }
          ],
          width: 0,
          setSideLines: true,
          justify: 'start'
        }, {
          roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
          btns: [{
              label: 'pages.repair.newRepair',
              icon: 'mdi-account-hard-hat',
              link: 'new-repair',
              roles: ['CLIENT'],
              children: null,
              highlight: false
            }, {
              label: 'pages.repair.actionRequired',
              icon: 'mdi-account-hard-hat',
              link: 'action-required',
              roles: ['SUPERVISOR', 'SERVICE', 'CLIENT'],
              children: null,
              highlight: false,
              notificationCount: () => this.$actionRequired.allNumber
            }, {
              label: 'pages.repair.activeRepairs',
              icon: 'mdi-file-document',
              link: 'active-repairs',
              roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
              children: null,
              highlight: false
            }, {
              label: 'pages.repair.closedRepair',
              icon: 'mdi-chart-bar',
              link: 'closed-repairs',
              roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
              children: null,
              highlight: false
            }, {
              label: 'pages.repair.reports',
              icon: 'mdi-chart-pie',
              roles: ['ADMIN', 'SUPERVISOR'],
              children: [
                {
                  label: 'pages.repair.generalReport',
                  icon: null,
                  link: 'reports/general',
                  roles: ['ADMIN', 'SUPERVISOR'],
                }
              ],
              highlight: false
            }, {
              label: 'pages.contact.users',
              icon: 'mdi-account-group',
              roles: ['ADMIN'],
              children: [
                {
                  label: 'pages.contact.userList',
                  icon: null,
                  link: 'contacts',
                  roles: ['ADMIN'],
                }, {
                  label: 'pages.contact.newContact',
                  icon: null,
                  link: 'create/contact',
                  roles: ['ADMIN'],
                }
              ],
              highlight: false
            }, {
              label: 'pages.mail.settings',
              icon: 'mdi-cog',
              roles: ['ADMIN'],
              children: [
                {
                  label: 'pages.mail.mailNotification',
                  icon: null,
                  link: 'mail-notification',
                  roles: ['ADMIN'],
                }, {
                  label: 'pages.part.parts',
                  icon: null,
                  link: 'parts',
                  roles: ['ADMIN'],
                }, {
                  label: 'pages.part.newPart',
                  icon: null,
                  link: 'create/part',
                  roles: ['ADMIN'],
                }
              ],
              highlight: false
            }, {
              label: 'pages.contact.profile',
              icon: 'mdi-account-circle',
              roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
              children: [
                {
                  label: 'pages.contact.userDetails',
                  icon: null,
                  link: 'profile',
                  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
                }, {
                  label: 'pages.contact.logout',
                  icon: null,
                  action: async () => {
                    await execHttpRequestThrowable(logout)
                    removeUserToken()
                    DynamicNotifier.notify('signInEvent')
                    routeFromRoot('login')
                  },
                  roles: ['ADMIN', 'SUPERVISOR', 'SERVICE', 'CLIENT', 'SUSTAINABILITY_MANAGER'],
                }
              ],
              highlight: false
            }
          ],
          width: 0,
          setSideLines: false,
          justify: 'start'
        } 
      ],
      identificationNotifier: null,
      numberOfActionRequiredAllRequestsIntervalId: null,
      navBtnGroupsConfig: {
        admin: {
          width: [45],
          justify: ['center']
        },
        supervisor: {
          width: [45],
          justify: ['center']
        },
        service: {
          width: [50, 30],
          justify: ['center', 'start']
        },
        client: {
          width: [45, 40],
          justify: ['center', 'end']
        },
        sustainability_manager: {
          width: [45, 40],
          justify: ['center', 'end']
        }
      }
    }
  },
  watch: {
    '$route.path': {
      handler() {
        this.findTheHighlightPage()
      },
      immediate: true
    }
  },
  computed: {
    getUsername() {
      if (this.identificationNotifier && this.identificationNotifier.static != this.identificationNotifier.dynamic) {
        DynamicNotifier.reset(this.identificationNotifier)
        return getUserIdentification()
      }
      else return ''
    },
    getRole() {
      return getUserRole()
    }
  },
  methods: {
    isGranted(roles) {
      return roles.includes(getUserRole())
    },
    routeTo(link) {
      routeFromRoot(link)
    },
    async tryToFetchContactData() {
      const r = await execHttpRequestThrowable(async () => await fetchContactByUsername(getUserIdentification()))
      this.$contactHolder.set(r.response.data)
      DynamicNotifier.notify('contactEvent')
    },
    findTheHighlightPage() {
      this.navBtnGroups.forEach(g => {
        g.btns.forEach(b => b.highlight = false)
      })

      for (const g of this.navBtnGroups) {
        for (const b of g.btns) {
          this.processHighlight(b)
        }
      }
    },
    processHighlight(b) {
      if (b.link) {
        if (this.$route.path.includes(b.link)) {
          b.highlight = true
          return
        }
      }
      else if (b.children && b.children !== undefined) {
        for (const c of b.children) {
          if (c.link) {
            if (this.$route.path.includes(c.link)) {
              b.highlight = true
              break
            }
          }
        }
      }
    },
    isRole(role) {
      return role === this.getRole
    },
    configGroups() {
      const role = this.getRole
      const width = this.navBtnGroupsConfig[role.toLowerCase()].width
      const justify = this.navBtnGroupsConfig[role.toLowerCase()].justify

      const groups = this.navBtnGroups.filter(g => g.roles.includes(role))
      for (let i = 0; i < width.length; i++) {
        groups[i].width = width[i]
      }
      for (let i = 0; i < justify.length; i++) {
        groups[i].justify = justify[i]
      }
    }
  },
  created() {
    this.configGroups()
  },
  async mounted() {
    this.identificationNotifier = DynamicNotifier.request('usernameEvent')

    if (this.getRole !== 'ADMIN' && this.getRole !== 'SUSTAINABILITY_MANAGER') {
      this.numberOfActionRequiredAllRequestsIntervalId = this.$actionRequired.startFetching()

      await this.$actionRequired.fetch()
    }
    await this.tryToFetchContactData()
  },
  beforeDestroy() {
    if (this.identificationNotifier) DynamicNotifier.delete(this.identificationNotifier, 'usernameEvent')
    if (this.numberOfActionRequiredAllRequestsIntervalId) clearInterval(this.numberOfActionRequiredAllRequestsIntervalId)
    this.$actionRequired.clearAll()
  }
}
</script>
<style>
.nav-bar-primary-color {
  background-color: #0d47a1!important;
}
.nav-bar-title {
  font-family: 'Titillium Web', sans-serif;
  font-size: 1.25rem;
  line-height: inherit;
  letter-spacing: 1px;
  font-weight: 300;
}
.nav-bar-group-btn-side-line {
  width: 2px;
  background: #83a4d6; 
  height: 20px; 
  border-radius: 80px;
}
</style>