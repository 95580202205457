<template>
  <v-text-field
  v-model="model"
  :rules="rules"
  :class="`sts-web-system-label ${disabled ? 'v-input--is-readonly' : ''}`"
  :label="$t('textFields.yourUsername')"
  @keypress.enter="$emit('enter')">
    <template #prepend>
      <v-icon
      v-if="icon"
      size="30"
      color="black">
        mdi-account
      </v-icon>
    </template>
  </v-text-field>
</template>
<script>
export default {
  props: {
    icon: Boolean,
    required: Boolean,
    value: String,
    error: Boolean,
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    min: {
      type: Number,
      default: null
    }
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  data() {
    return {
      rules: [
        (v) => {
          if (this.required) {
            return !!v || this.$t('invalidValue.required')
          }
          return true
        },
        (v) => {
          if (!!v && v.length > 0 && !!this.min) {
            return new RegExp(`^[a-zA-ZА-Яа-я0-9_]{${this.min},64}$`).test(v) || this.$t('invalidValue.invalidUsername')
          }
          return true
        }
      ]
    }
  }
}
</script>
<style>
.sts-web-system-label  .v-label.theme--light {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 300;
}
</style>