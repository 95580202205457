<template>
  <v-select
  v-model="model"
  :items="items"
  filled
  :label="$t(label)"
  :loading="loading"
  :disabled="disabled"
  :color="color"
  hide-details
  :readonly="readonly"
  :return-object="!itemValue"
  :item-text="itemText"
  :item-value="itemValue"
  :rules="rules"/>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    label: String,
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Object, String],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: null
    },
    itemText: {
      type: String,
      default: null
    },
    itemValue: {
      type: String,
      default: null
    }
  },
  model: {
    prop: 'value',
    event: 'valueChanged'
  },
  computed: {
    model: {
      get: function() {
        return this.$props.value
      },
      set: function(v) {
        this.$emit('valueChanged', v)
      }
    }
  },
  data() {
    return {
      rules: [
        v => {
          if (this.required) {
            return !!v || this.$t('invalidValue.required')
          }
          return true
        }
      ]
    }
  }
}
</script>
<style>
</style>